$(document).ready(function () {
  $('.daterangepicker-input').each(function(index, element) {
    args = {}

    args.autoUpdateInput = false
    args.showDropdowns = true
    args.timePicker = true
    args.timePicker24Hour = true
    args.timePickerSeconds = true
    args.autoApply = false
    args.parentEl = $(element).closest('daterangepicker-container')

    var startDate = $(element).attr('data-daterangepicker-start-date');
    if (startDate) {
      args.startDate = startDate;
    }

    var endDate = $(element).attr('data-daterangepicker-start-date');
    if (endDate) {
      args.endDate = endDate;
    }

    $(element).daterangepicker(args);
  });

  $('.daterangepicker-input').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY HH:mm:ss') + ' - ' + picker.endDate.format('MM/DD/YYYY HH:mm:ss'));
    $(ev.target).trigger('change');
  });

  $('.daterangepicker-input').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
    $(ev.target).trigger('change');
  });
})
