import React from 'react';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {BrowserRouter as Router, Link, Route} from 'react-router-dom'

import reducer from '../reducers/serviceReducer';
import ServiceParentComponent from '../parents/ServiceParentComponent';

export default (props, _railsContext) => {
  const store = createStore(reducer, props, applyMiddleware(thunk));

  let logger = () => {console.log(store.getState());}
  // store.subscribe(logger);

  return(
    <Provider store={store}>
      <ServiceParentComponent />
    </Provider>
  )
}
