app.directive('marketSegment', ['$filter', 'Segment', function($filter, Segment) {
  return {
    link: function(scope, element, attrs) {
      var segments = [];
      var queried = false;
      scope.$watch(attrs.ngModel, function(data) {
        if ( !queried ) {
          Segment.query().then(function(res){
            segments = res.stores;
            queried = true;
          });
        }

        if ( segments.length > 0 ) {
          scope.visible_segments = $filter('filter')(segments, {clientId: attrs.clientId})
          scope.apply
        }
      });
    }
  }
}]);
