app.factory('AppointmentSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('route_id', 'service_order_id', 'long_lead_time_reason');
  });
}]);

app.factory('AssignmentSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('activity_type', 'activity_id', 'date', 'employee_id',
      'activity', 'created_at', 'updated_at', 'employee', 'team',
      'background_color', 'text_color', 'automatic');
  });
}]);

app.factory('AssignmentNoteSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('text', 'date', 'employee_id');
  });
}]);

app.factory('CustomerSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('first_name', 'last_name', 'email', 'location_attributes',
      'phone_numbers_attributes');
    this.nestedAttribute('location', 'phone_numbers');
    this.resource('location', 'Location');
    this.serializeWith('phone_numbers', railsSerializer(function() {
      this.only('description', 'extension', 'number', 'id', 'allow_sms');
    }));
  });
}]);

app.factory('FurnitureSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('id', 'service_order_id', 'partner_id', 'description',
      'delivery_date', 'sku', 'model', 'quantity', 'material', 'color',
      'vendor', 'issues', 'issues_attributes', 'serial_number');
    this.nestedAttribute('issues');
    this.resource('issues', 'Issue');
  });
}]);

app.factory('FurnitureReportSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('outcome', 'comments', 'cause', 'usage', 'tech_report_id',
      'furniture_id', 'furniture', 'serial_number', 'sku', 'model',
      'color', 'material', 'quantity', 'delivery_date', 'vendor',
      'mattress_reports', 'cleaning_reports', 'core_reports', 'repair_reports',
      'client_review_reports');
    this.nestedAttribute('furniture', 'mattress_reports', 'cleaning_reports',
      'core_reports', 'repair_reports', 'client_review_reports');
    this.resource('furniture', 'Furniture');
  });
}]);

app.factory('IssueSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('description', 'intent', 'item_id', 'partner_id', 'id');
  });
}]);

app.factory('LocationSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('street1', 'street2', 'city', 'state', 'zip', 'zipcode',
      'longitude', 'latitude', 'coordinates', 'cross_street', 'id');
  });
}]);

app.factory('PartOrderSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.resource('lines', 'PartOrderLine');
  });
}]);

app.factory('PartOrderLineSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('part_order_id', 'part_id', 'quantity', 'price_each');
  });
}]);

app.factory('ServiceOrderSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('client_id', 'partner_id', 'customer_id', 'custom_1_title',
      'custom_1_value', 'custom_2_title', 'custom_2_value', 'custom_3_title',
      'custom_3_value', 'custom_4_title', 'custom_4_value', 'work_order',
      'episode', 'bill_strategy', 'instructions', 'estimated_time',
      'original_service_order_id', 'zone_id', 'pickup_location_id',
      'schedule_request_id', 'part_order_id', 'original_service_order',
      'progenitor_type', 'progenitor_id', 'use_auto_call', 'hold_until',
      'store_id', 'cancellation_id');
  });
}]);

app.factory('RepairReportSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('furniture_report_id', 'comments', 'outcome', 'more_time',
      'parts', 'time_to_complete', 'parts_reports_items_attributes',
      'replacement_parts_attributes', 'issue_ids');
    this.nestedAttribute('replacement_parts');
    this.nestedAttribute('parts_reports_items');
    this.serializeWith('replacement_parts', railsSerializer(function() {
      this.only('id', 'description', 'sub_report_id', 'sub_report_type');
    }));
    this.serializeWith('parts_reports_items', railsSerializer(function() {
      this.only('id', 'part_id', 'quantity');
    }));
  });
}]);

app.factory('RouteSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.add('technician_id', function(route) {
      if (route.technician) { return route.technician.id; }
      else if (route.technicianId) { return route.technicianId; }
      else if (route.technician_id) { return route.technician_id; }
      else { console.log('Serializer did not find a technician id'); }
    });
    this.exclude('id', 'name', 'max_duration', 'total_duration', 'assigned_zone_names', 'technician', 'waypoints', 'location_id', 'description');
    this.nestedAttribute('origin');
    this.nestedAttribute('destination');
  });
}]);

app.factory('TechReportSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('appointment', 'appointment_id', 'time_in', 'time_out', 'at_home');
    this.nestedAttribute('not_at_home_report', 'pieces', 'pickup_reports');
  });
}]);

app.factory('UserSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('email', 'crypted_password', 'password_salt', 'persistence_token', 'single_access_token', 'perishable_token', 'login_count', 'failed_login_count', 'current_login_at', 'last_login_ip', 'client_id', 'employee_id', 'cell_email', 'active');
    this.nestedAttribute('employee');
  });
}]);

app.factory('VarianceSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('appointment_id', 'description', 'duration', 'resolution', 'reason');
  });
}]);

app.factory('EmployeeSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.add('schedule_profile', function(employee) {
      return employee.schedule_profile.attributes;
    });
    this.serializeWith('schedule_profile', 'ScheduleProfileSerializer');
    this.only('schedule_profile', 'default_activity_id');
  });
}]);

app.factory('ScheduleProfileSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this
  });
}]);

app.factory('WarrantyRegistrationSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.exclude('state');
  });
}]);

app.factory('PreQItemSerializer', ['railsSerializer', function(railsSerializer) {
  return railsSerializer(function() {
    this.only('id', 'pre_q_id', 'partner_id', 'description', 'delivery_date', 'sku', 'model', 'quantity', 'material', 'color', 'vendor', 'issues', 'issues_attributes', 'serial_number');
    this.nestedAttribute('issues');
    this.resource('issues', 'Issue');
  });
}]);
