import React from 'react';
import Address from '../common/Address';
import Fact from '../common/Fact';
import {getFuzzies, getMatches, getSuggestions} from '../../tools/mapping';

export default class Disambiguator extends React.Component {
  constructor(props) {
    super(props);
    this.renderMatch = this.renderMatch.bind(this);
    this.renderFuzzies = this.renderFuzzies.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
  }

  renderLocation(location)
  {
    let key = location.linkId || ("" + location.street1 + location.latitude)

    return(
      <div className="location-suggestion" key={key}>
        <Address location={location} key={location.linkId || ("" + location.street1 + location.latitude)} />
      </div>
    )
  }

  renderMatch(location)
  {
    let key = location.linkId || ("" + location.street1 + location.latitude)
    return(
      <div className="location-suggestion-container" key={key}>
        <div className="location-suggestion-action" style={{display: 'inline-block'}}>
          <button onClick={() => {this.props.onChange(location)}} className="btn btn-default">Use This One</button>
        </div>
        <div style={{display: 'inline-block'}}>
          <Address location={location} />
        </div>
      </div>
    )
  }

  renderMatches(locations)
  {
    if(locations.length > 0)
      return(
        <div>
          {locations.map(this.renderMatch)}
        </div>
      )
    else
      return null
  }

  renderFuzzies(locations)
  {
    if(locations.length > 0)
      return(
        <div>
          {locations.map(this.renderMatch)}
        </div>
      )
    else
      return null
  }

  renderSuggestions(locations)
  {
    if(locations.length > 0)
    {
      return(
        <div>
          <h3>Inexact Matches</h3>
          {locations.map((loc) => {return this.renderLocation(loc)})}
        </div>
      )
    }
    else
    {
      return null
    }
  }

  renderAlert(matches, fuzzies) {
    if (matches.length > 0 || fuzzies.length > 0) {
      return(
        <div className="alert alert-warning fancy-alert alert-tall">
          <div className="alert-content">
            Please, select one of the addresses below to use its coordinates.
          </div>
        </div>
      )

    }
  }

  render()
  {
    let matches = getMatches(this.props.locations);
    let suggestions = getSuggestions(this.props.locations);
    let fuzzies = getFuzzies(this.props.locations);

    if((this.props.locations.length) == 0)
      return null
    else
      return(
        <div className="location-suggestions">
          {this.renderAlert(matches, fuzzies) }
          {this.renderMatches(matches)}
          {matches.length > 1 ? null : this.renderFuzzies(fuzzies)}
          {this.renderSuggestions(suggestions)}
        </div>
    )
  }
}
