import React from 'react';
import { render } from 'react-dom';
import TextInput from '../common/TextInput';
import { arrayEquality } from '../../tools/arrayOperations'

export default class LocationForm extends React.Component
{
  constructor(props)
  {
    super(props);
    this.updateLocation = this.updateLocation.bind(this);
  }

  updateLocation(name, value)
  {
    this.props.onChange(name, value);
  }

  render()
  {
    return(
      <div>
        <TextInput label="Zipcode" name="zipcode" value={this.props.location.zipcode} required={true} autocomplete="none" onChange={this.updateLocation} focus={true} type="number" />
        <TextInput label="Street" name="street1" value={this.props.location.street1} required={true} autocomplete="none" onChange={this.updateLocation} changeDelay={2500} />
        <TextInput label="" name="street2" value={this.props.location.street2} required={true} autocomplete="none" onChange={this.updateLocation} />
        <TextInput label="City" name="city" value={this.props.location.city} required={true} autocomplete="none" onChange={this.updateLocation} changeDelay={500} />
        <TextInput label="State" name="state" value={this.props.location.state} required={true} autocomplete="none" onChange={this.updateLocation} changeDelay={500} />
        <br />
        <TextInput label="Nearest Cross Street" name="cross_street" value={this.props.location.cross_street} required={true} autocomplete="none" onChange={this.updateLocation} />
      </div>
    )
  }
}
