app.controller('BillingOverviewCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Client', 'PreQ',
  function($scope, $routeParams, $location, fixRailsSerializer, Client, PreQ ) {
    $scope.clientsLoaded = false;
    $scope.preQsLoaded = false;

    Client.query({has_pending_bills: true, sort_order: 'frequent' }).then(
      function( resources )
      {
        $scope.clients_with_billable_service_orders = resources;
        $scope.clientsLoaded = true;
      }
    );

    PreQ.$get('/pre_qs/billable').then(
      function( resources )
      {
        $scope.clients_with_billable_pre_qs = [];

        angular.forEach( resources, function( pq )
        {
          if( $scope.clients_with_billable_pre_qs.indexOf( pq.client.id ) == -1 )
          {
            $scope.clients_with_billable_pre_qs.push( pq.client );
          }
        });
        $scope.preQsLoaded = true;
      }
    );
  }
]);
