app.service('Calendar', [function() {
  this.today = function() {
    return new Date();
  };

  this.stringToDate = function(input) {
    if (input) {
      var parts = input.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]);
      // months are 0-based. :(
    }
  };

  this.padding = function(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  this.dateToString = function(date, format) {
    if (format === undefined) {
      format = "ymd";
    }

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (format === "mdy") {
      return this.padding(month, 2) + "/" + this.padding(day, 2) + "/" + year;
    } else if (format === 'ymdhms') {
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      return year + this.padding(month, 2) + this.padding(day, 2) + this.padding(hour, 2) + this.padding(minute, 2) + this.padding(second, 2);
    } else {
      return year + "-" + this.padding(month, 2) + "-" + this.padding(day, 2);
    }
  };

  this.addDate = function(date, change) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + change);
  };

  this.monday_after = function(date) {
    var sunday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    var next_monday = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 8);
    return next_monday;
  };

  this.monday = function() {
    date = new Date();
    var sunday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    var monday = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 1);
    return monday;
  };

  this.stringToTime = function(string, date) {
    if (string !== undefined) {
      var output = date || new Date();
      var time = string.match(/(\d+):(\d\d) ([aA]|[pP])[mM]/);

      if (time == null) {
        return null;
      }

      var h = parseInt(time[1]);
      var m = parseInt(time[2]);
      var meridian = time[3];

      if (h == 12 && (meridian == 'A' || meridian == 'a')) {
        h = 0;
      }

      if ((meridian == 'P' || meridian == 'p') && h < 12) {
        h += 12;
      }

      output.setHours(h);
      output.setMinutes(m);

      return output;
    }
  };

  this.hoursToDuration = function(hours) {
    var d = Number(hours) * 60 * 60;
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    return ((h > 0 ? h + ":" : "0:") + (m > 0 ? (h > 0 && m < 10 ? "0" : "") + m + "" : "00"));
  };
}]);
