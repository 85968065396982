import {connect} from 'react-redux';
import NewCustomer from '../../components/Customers/Form';
import {customerSaveThunk} from '../../thunks/customerThunk';

let mapStateToProps = (state, ownprops) => {

  return(
  {
    customer: {first_name: '', last_name: '',
                 phone_numbers: [],
                 location: {},
                 mapping_address: { location: {} }
              }
  });
}

let mapDispatchToProps = (dispatch) => {
  return {
    customerSave: (data) => {
      return dispatch(customerSaveThunk(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);
