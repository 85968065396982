app.controller('ServiceOrderCtrl', [
  '$scope', '$http', 'fixRailsSerializer', 'RecordedCall', 'ServiceOrder',
  function($scope, $http, fixRailsSerializer, RecordedCall, ServiceOrder) {

    function load_service_order(id)
    {
      ServiceOrder.get( id ).then( function(response)
      {
        $scope.service_order = response;
      });
    }

    $scope.initialize = function( atts )
    {
      $scope.showFollowUpForm = false;
      load_service_order(atts.service_order_id);
    }

    $scope.toggle_flagged = function( recorded_call_id )
    {
      new RecordedCall( { id: recorded_call_id } ).update()
    }

  }
]);
