app.config(["$httpProvider", function(provider)
{
  provider.defaults.headers.common['X-CSRF-Token'] = $('meta[name=csrf-token]').attr('content');

  // Start the spinner
  provider.defaults.transformRequest.push(function (data, headersGetter) {
    $('#api-error').hide();
    $('#api-access').show();
    return data;
  });

  provider.responseInterceptors.push('apiErrorInterceptor');
}]);

app.factory('Activity', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/activities',
    name: 'activity',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Appointment', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/appointments',
    name: 'appointment',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'AppointmentSerializer'
  });
}]);

app.factory('Assignment', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/assignments',
    name: 'assignment',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'AssignmentSerializer'
  });
}]);

app.factory('AssignmentNote', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/assignment_notes',
    name: 'assignment_note',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'AssignmentNoteSerializer'
  });
}]);

app.factory('Bill', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/bills',
    name: 'bill',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('BillsNote', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/bills/{{bill_id}}/notes',
    name: 'note',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Call', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/calls',
    name: 'call',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Client', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/clients',
    name: 'client',
    interceptors: ['jsonURLInterceptor']
  });
  // https://github.com/FineLinePrototyping/angularjs-rails-resource/pull/12
}]);

app.factory('Customer', ['railsResourceFactory', function(railsResourceFactory)
{
  var customer = railsResourceFactory({
    url: '/customers',
    name: 'customer',
    interceptors: ['jsonURLInterceptor', 'deletePhoneInterceptor'],
    serializer: 'CustomerSerializer'
  });

  customer.prototype.full_name = function() {
    return this.first_name + ' ' + this.last_name;
  };

  return customer;
}]);

app.factory('Employee', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/employees',
    name: 'employee',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'EmployeeSerializer'
  });
}]);

app.factory('Furniture', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/furniture',
    name: 'furniture',
    interceptors: ['jsonURLInterceptor', 'deleteIssueInterceptor'],
    serializer: 'FurnitureSerializer'
  });
}]);

app.factory('FurnitureReport', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/furniture_reports',
    name: 'furniture_report',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'FurnitureReportSerializer'
  });
}]);

app.factory('Issue', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/issues',
    name: 'issue',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'IssueSerializer'
  });
}]);

app.factory('Location', ['railsResourceFactory', 'Zipcode', function(railsResourceFactory, Zipcode)
{
  var location = railsResourceFactory({
    url: '/locations',
    name: 'location',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'LocationSerializer'
  });

  location.prototype.update_zipcode = function() {
    var self = this;
    if( self.zipcode && self.zipcode.length == 5) {
      Zipcode.$get('/zipcodes/search.json', {code: self.zipcode}).then(
        function( response ) {
          if( response.length == 1 ) {
            self.city = response[0].city;
            self.state = response[0].state;
            if( self.longitude == null && self.latitude == null ) {
              self.latitude = response[0].latitude;
              self.longitude = response[0].longitude;
            }
          }
        }
      );
    }
  };

  return location;
}]);

app.factory('Part', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/parts/list',
    name: 'part',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('PartGroup', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/part_groups',
    name: 'part_group',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('PartOrder', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/part_orders',
    name: 'part_order',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'PartOrderSerializer'
  });
}]);

app.factory('PartOrderLine', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/part_order_lines',
    name: 'part_order_line',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'PartOrderLineSerializer'
  });
}]);

app.factory('PickupLocation', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/pickup_locations',
    name: 'pickup_location',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('PreQ', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/pre_qs',
    name: 'pre_q',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('PreQItem', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/pre_q_items',
    name: 'pre_q_item',
    interceptors: ['jsonURLInterceptor', 'deleteIssueInterceptor'],
    serializer: 'PreQItemSerializer'
  });
}]);

app.factory('RecordedCall', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/recorded_calls',
    name: 'recorded_call',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('RepairReport', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/repair_reports',
    name: 'repair_report',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'RepairReportSerializer'
  });
}]);

app.factory('Route', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/routes',
    name: 'route',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'RouteSerializer'
  });
}]);

app.factory('ServiceOrder', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/service_orders',
    name: 'service_order',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'ServiceOrderSerializer'
  });
}]);

app.factory('Segment', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/market_segments',
    name: 'segment',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Superzone', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/superzones',
    name: 'superzone',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('TechReport', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/appointments/{{appointment_id}}/tech_report',
    name: 'tech_report',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'TechReportSerializer'
  });
}]);

app.factory('Technician', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/technicians',
    name: 'technician',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Topic', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/topics',
    name: 'topic',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('UnmanagedRoute', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/unmanaged_routes',
    name: 'unmanaged_route',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('User', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/users',
    name: 'user',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'UserSerializer'
  });
}]);

app.factory('Variance', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/variances',
    name: 'variance',
    serializer: 'VarianceSerializer',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('WarrantyRegistration', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/warranty_registrations',
    name: 'warranty_registration',
    interceptors: ['jsonURLInterceptor'],
    serializer: 'WarrantyRegistrationSerializer'
  });
}]);

app.factory('Waypoint', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/waypoints',
    name: 'waypoint',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('WorkTimeEstimate', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/work_time_estimates',
    name: 'work_time_estimate',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Zipcode', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/zipcodes',
    name: 'zipcodes',
    interceptors: ['jsonURLInterceptor']
  });
}]);

app.factory('Todo', ['railsResourceFactory', function(railsResourceFactory)
{
  return railsResourceFactory({
    url: '/todos/count',
    name: 'todos',
    interceptors: ['jsonURLInterceptor']
  });
}]);

