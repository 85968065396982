let getLocationQuality = function(qualityCode)
{
  let granularity = qualityCode.substr(0,1);
  let confidence  = qualityCode.substr(2,5).split('');

  let goodGranularity = (granularity == 'P' || granularity == 'L');
  let goodQuality = confidence.every((q)=>{return(q == 'A' || q == 'B')});
  let suggestableGranularity = (granularity == 'B');

  if(goodGranularity && goodQuality)
    return "match"
  else if(suggestableGranularity)
    return "suggestion"
  else if(goodGranularity)
    return "fuzzy"
  else
    return "bad"
}

let getMatches = function(locations)
{
  // https://developer.mapquest.com/documentation/geocoding-api/quality-codes/
  return locations.filter( (location) => location.quality == 'match' )
}

let getSuggestions = function(locations)
{
  // https://developer.mapquest.com/documentation/geocoding-api/quality-codes/
  return locations.filter((location) => location.quality == 'suggestion')
}

let mqToLocation = function(mq_loc)
{
  return {
    street1: mq_loc.street,
    zipcode: mq_loc.postalCode && mq_loc.postalCode.substring(0,5),
    city: mq_loc.adminArea5,
    state: mq_loc.adminArea3,
    latitude: mq_loc.latLng.lat,
    longitude: mq_loc.latLng.lng,
    quality: getLocationQuality(mq_loc.geocodeQualityCode),
    raw_data: mq_loc
  }
}

let geocodeWithMQ = function(atts)
{
  let promise = new Promise((resolve, reject) =>
  {
    let success = (response) => {
      let locations = response.results[0].locations.map(mqToLocation);
      resolve(locations);
    }
    let failure = (response) => {
      try {
        var jsonResponse = JSON.parse(response.responseText);
        reject(jsonResponse['error']);
      } catch (e) {
        reject('Something went wrong ' + response.responseText);
      }
    }

    let loc = {
      street1: atts.location.street1 || '',
      street2: atts.location.street2 || '',
      city: atts.location.city || '',
      state: atts.location.state || '',
      zipcode: atts.location.zipcode || ''
    }

    $.get('/map_quests/geocoder.json', { location: loc }).then(success, failure);
  })

  return promise;
}

let sufficientStreet1 = function(location)
{
  return(location.street1 && location.street1.length >= 6);
}

export {geocodeWithMQ}
