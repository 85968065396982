app.controller('ServiceOrderItemsCtrl', [
  '$scope', '$routeParams', 'fixRailsSerializer', 'ServiceOrder', 'Furniture', 'FurnitureDescriptions',
  function($scope, $routeParams, fixRailsSerializer, ServiceOrder, Furniture, FurnitureDescriptions) {
    $scope.popular_descriptions = FurnitureDescriptions;
    $scope.parent_description = "Service Order";

    ServiceOrder.get( $routeParams['service_order_id'] ).then(function( resource )
    {
      $scope.service_order = resource;
      $scope.parent_url = "/service_orders/" + $routeParams['service_order_id'];
      $scope.items = $scope.service_order.items;
      $scope.furniture = {issues: [{}], quantity: 1, service_order_id: $scope.service_order.id };
    });

    $scope.issue_submitted = function()
    {
      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue.description !== "" && last_issue.description !== undefined )
      {
        $scope.furniture.issues.push({});
      }
    }

    $scope.save_item = function()
    {
      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue.description !== "" && last_issue.description !== undefined )
      {
        i = $scope.furniture.issues.indexOf( last_issue );
        $scope.furniture.issues.splice(i, 1);
      }

      var data = $scope.furniture;
      var item = new Furniture( data );

      if( item.id !== undefined )
      {
        var index = $scope.items.findIndex(function(i) {
          return i.id == item.id;
        });

        item.update().then(function(data) {
          $scope.furniture = { issues: [{}], quantity: 1, service_order_id: $scope.service_order.id }
          $scope.items[index] = data;
        })
      }
      else
      {
        item.create().then( function()
        {
          $scope.items.push(item);
          $scope.furniture = {issues: [{}], quantity: 1, service_order_id: $scope.service_order.id };
        });
      }
    }

    $scope.edit_item = function( item )
    {
      $scope.furniture = item;

      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue === undefined || ( last_issue.description !== "" && last_issue.description !== undefined ) )
      {
        $scope.furniture.issues.push({});
      }
    }

    $scope.delete_item = function( item )
    {
      Furniture.$delete("/service_orders/" + $scope.service_order.id + "/items/" + item.id).then(
        function()
        {
          i = $scope.items.indexOf( item );
          $scope.items.splice(i, 1);
        }
      );
    }
  }
]);
