import React from 'react';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import MultiPictureReducer from '../reducers/MultiPictureReducer';
import MultiPictureThing from '../containers/MultiPictureContainer';

export default (props, _railsContext) => {
  let defaultStoreState = { pictures: [], uploaded_picture_count: 0, appointment_id: props.appointment_id, service_order_id: props.service_order_id }

  const store = createStore(MultiPictureReducer, defaultStoreState, applyMiddleware(thunk));

  let logger = () => {console.log(store.getState());}
  // store.subscribe(logger);

  // Here, we could dispatch any thunks needed to get initialization data
  // store.dispatch( myLoaderThunk )

  return(
    <Provider store={store}>
      <MultiPictureThing />
    </Provider>
  )
}
