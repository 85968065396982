window.Rainbow = new function() {
  let self = this;

  red_dark = "#CA0C26";
  red_pale = "pink"; // or 664A2A

  green_pastel = "#99FF66";
  green_alt = "#87BA1A";
  green_dark = "#044511";
  green_clear = "#BBCBC1";

  blue_medium = "#0086A6";
  blue_pale = "#88DDFF";

  yellow_bright = "#FAF060";
  purple_pastel = "#B17DF5";
  purple_dark = "#220066";

  brown_light = "#EA7B03";
  brown_dark = "#140907";

  self.colors = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928', red_dark, green_pastel, purple_dark, yellow_bright, purple_pastel, green_dark, brown_light, green_clear, blue_medium, brown_dark, red_pale, blue_pale, '#F84201', '#F9D9C7', '#7060E7', '#C340c5', '#538662', '#7EC5FC', '#7F6F25', '#BCE5DD', '#E1CC9C', '#907B70', '#424E10', '#D06748', '#1BF9D3', '#9ECAA1', '#A62617', '#12C6B4'];

  self.current_index = undefined;

  self.next_index = function() {
    if(self.current_index == undefined)
    {
      return( self.current_index = 0 );
    }
    else
    {
      return( self.current_index += 1 );
    }
  };

  self.get_color = function() {
    return( this.colors[ this.next_index() ] );
  };

  self.reset = function() {
    self.current_index = undefined;
  }
}();

