app.controller('BillablePreQsCtrl', [
  '$scope', '$routeParams', '$location', '$window', 'fixRailsSerializer', 'Client', 'PreQ', 'Calendar',
  function($scope, $routeParams, $location, $window, fixRailsSerializer, Client, PreQ, Calendar ) {
    $scope.todays_date = Calendar.dateToString( new Date() );

    Client.get($routeParams['client_id']).then(
      function( resource )
      {
        $scope.client = resource;
        load_billable_pre_qs();
      }
    );

    function load_billable_pre_qs()
    {
      PreQ.$get('/pre_qs/billable').then(function(resources)
      {
        $scope.bills = [];

        angular.forEach( resources, function(pq)
        {
          if( pq.client.id == $scope.client.id )
          {
            $scope.bills.push(pq);
          }
        });
      });
    }

    /*$scope.changed = function( bill, attribute, value )
    {
      change = {};
      change[attribute] = value;
      // ha ha, you can't do {attribute:value}

      Bill.$put("/bills/" + bill.id, change );
    }

    $scope.selections = function()
    {
      var output = [];
      angular.forEach( $scope.bills, function(bill)
      {
        if( bill.selected )
        {
          output.push( bill );
        }
      });

      return output;
    }

    $scope.print_tech_reports = function( bills )
    {
      var tech_report_ids = "";
      angular.forEach(bills, function(bill)
      {
        if( tech_report_ids == "" )
        {
          tech_report_ids = bill.tech_report_id.toString();
        }
         else
        {
          tech_report_ids = tech_report_ids + "," + bill.tech_report_id.toString();
        }
      });

      $window.location.href = "/tech_reports/print?ids=" + tech_report_ids;
    }

    */
  }
]);
