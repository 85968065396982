export default function receiveMultiPictureThunk(appointment_id, file)
{
  return function(dispatch, getState)
  {
    dispatch({
      type: 'RECEIVE_PICTURE',
      file: file
    });

    let success = (response) => { dispatch({type: 'UPLOAD_SUCCEEDED'}) }

    let failure = (response) => { dispatch({type: 'UPLOAD_FAILURE'}) }

    $.post('/appointments/' + appointment_id + '/pictures.json',
       {picture: {image: file}}).then(success, failure);
  }
}
