import React from 'react';
import PropTypes from 'prop-types';

export default class BootstrapControl extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string
  }

  constructor(props) {
    super(props);
  }

  controlGroupClass( required, error )
  {
    if( error )
      return "control-group error"
    else if( required )
      return "control-group required"
    else
      return "control-group"
  }

  render() {
    return (
      <div className={this.controlGroupClass(this.props.required, this.props.error)} >
        <label htmlFor={this.props.name} className="control-label">
          {this.props.label}
        </label>
        <div className="controls">
          {this.props.children}
          {this.renderHint(this.props.hint, this.props.error)}
        </div>
      </div>
    );
  }

  renderHint(hint, error)
  {
    if(error)
      return(
        <p className="help-block">{error}</p>
      )
    else if(hint)
      return(
        <p className="help-block">{hint}</p>
      )
    else
      return null;
  }
}
