// stackoverflow.com/questions/11850025/recommended-way-of-getting-data-from-the-server/11850027#11850027

app.factory('Day', ['Calendar', function(Calendar) {
  var Day = function(data) {
    angular.extend(this, data);
    this.assignments = [];
  };

  Day.prototype.note = "note";

  return Day;
}]);
