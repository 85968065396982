app.directive('formatTime', ['$parse', '$filter',  function($parse, $filter) {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$formatters.push( function(value) {
        var value = new Date(value).toTimeString().split(':');
        return value[0] + ':' + value[1];
      });
    }
  };
}]);
