app.controller('FollowUpServiceOrdersCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'ServiceOrder', 'Furniture', 'PickupLocation', 'Customer', 'Client', 'Segment',
  function($scope, $routeParams, $location, fixRailsSerializer, ServiceOrder, Furniture, PickupLocation, Customer, Client, Segment ) {
    $scope.store_id = document.current_user_store_id;
    $scope.segment_set = false
    ServiceOrder.get( $routeParams['service_order_id'] ).then(function( original )
    {
      $scope.pickup_locations = PickupLocation.query();

      $scope.service_order =
      {
        client_id:   original.client.id,
        customer_id: original.customer.id,
        client_name: original.client.name,
        original_service_order_id: original.id,
        bill_strategy: $routeParams['bill_strategy'] || 'Normal',

        custom_1_title: original.custom_1_title,
        custom_1_value: original.custom_1_value,

        custom_2_title: original.custom_2_title,
        custom_2_value: original.custom_2_value,

        custom_3_title: original.custom_3_title,
        custom_3_value: original.custom_3_value,

        custom_4_title: original.custom_4_title,
        custom_4_value: original.custom_4_value,

        store_id: original.store_id
      };

      Segment.query({client_id: original.client.id}).then(function(res){
        $scope.segments = res.stores;
        if (original.store_id) {
          $scope.segment_set = true
          set_segment_name(original.store_id)
        }
      });

      $scope.bill_strategy_readonly = !!$routeParams['bill_strategy'];
      $scope.furniture = original.items;

      if( $routeParams['bill_strategy'] == 'Recall' )
      {
        $scope.service_order.work_order = original.work_order;
      }
    });

    function set_segment_name(id) {
      for (var i = 0; i < $scope.segments.length; i ++) {
        if ($scope.segments[i].id == id) {
          $scope.service_order.store_name = $scope.segments[i].name
        }
      }
    }

    function load_customer()
    {
      if( $scope.service_order )
      {
        Customer.$get('/customers/' + $scope.service_order.customer_id + '/serviceable.json').then(function(response){
          $scope.service_order.customer = response;
          // if there is a store in the customer's zipcode
          if(response.local_stores.length > 0)
          {

            angular.forEach(response.local_stores, function(needle)
            {
              if( needle.client_id == $scope.service_order.client_id )
              {
                $scope.service_order.store_id = needle.id;
              }
            })
          }
        });
      }
    }

    $scope.$watch('service_order', load_customer);

    $scope.create_service_order = function()
    {
      function disable_submit()
      {
        $('button[data-loading-text]').button('loading');
      }

      function enable_submit()
      {
        $('button[data-loading-text]').button('reset');
      }

      function visit_service_order_items_page(service_order)
      {
        $location.path('/service_orders/' + service_order.id + '/items');
      }

      function post_processing( service_order )
      {
        $.each( $scope.furniture, function(index, item)
        {

          var issues_attributes = []

          if (item.issues) {
            $.each(item.issues, function (index, issue) {
              issues_attributes.push({
                description: issue.description,
                intent: issue.intent
              })
            })
          }

          new Furniture({
                service_order_id: service_order.id,
                description: item.description,
                issues_attributes: issues_attributes,
                sku: item.sku,
                model: item.model,
                color: item.color,
                material: item.material,
                quantity: item.quantity,
                delivery_date: item.delivery_date,
                vendor: item.vendor
                }).create();
        });

        visit_service_order_items_page(service_order);
      }

      event.preventDefault();
      disable_submit();
      (new ServiceOrder($scope.service_order)).create().then( post_processing, enable_submit )
    }

    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
  }
]);
