app.controller('RouteManagerCtrl', [
  '$scope', '$controller', '$routeParams', '$location', '$http', 'fixRailsSerializer', 'Route', 'Superzone',
  function($scope, $controller, $routeParams, $location, $http, fixRailsSerializer, Route, Superzone) {
    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    $controller('RouteSharedCtrl', { $scope: $scope, Route: Route });

    $.post('/observations.json', {observations: {identifier: 'Route Manager outline', value: document.current_user_id || 0}});
    $scope.mapHelper.reset();
    $scope.events = [];

    Superzone.query({status: 'In Service'}).then(
      function(resources)
      {
        $scope.selectable_superzones = resources;
      });

    $scope.highlight_route = function(route)
    {
      let polygon = Object.values(route.outline._layers)[0];
      polygon.setStyle({
        'fillOpacity': 0.8,
        'opacity': 0.8
      });

      let circle = Object.values(route.outline._layers)[1];
      circle.setStyle({
        'fillOpacity': 0.8,
        'opacity': 0.8
      });
    }

    $scope.unhighlight_route = function(route)
    {
      let polygon = Object.values(route.outline._layers)[0];
      polygon.setStyle({
        'fillOpacity': 0.5,
        'opacity': 0.3
      });

      let circle = Object.values(route.outline._layers)[1];
      circle.setStyle({
        'fillOpacity': 0.5,
        'opacity': 0.3
      });
    }

    $scope.load_routes = function()
    {
      if( $scope.superzone && $scope.date )
      {
        $scope.mapHelper.reset().then(function () {
          Route.$get('/route_search', {superzone_id: $scope.superzone.id, date: $scope.date}).then(function(resource) {
            $scope.routes = resource.route_searches;
            add_routes_to_map( $scope.routes );
            // Poor man's promise. We need to wrap add_route_to_map() in a promise.
            setTimeout(function(){
              set_up_sorting();
              $scope.$apply();
            }, 2500);
          });

          $scope.$apply();
        })
      }
    };

    function add_routes_to_map(routes) {
      $scope.mapHelper.outlineRoutes(routes, true, {});
    }

    $scope.show_info_about = function( waypoint )
    {
       $scope.active_waypoint = waypoint;
    };

    $scope.find_route = function( id )
    {
      output = undefined;

      angular.forEach( $scope.routes, function( item )
      {
        if( item.id == id )
        {
          output = item;
        }
      });

      return( output );
    };

    $scope.dragStart = function(e, ui) {
      var route_id = $($(ui.item[0]).closest('.route')).data('id');
      ui.item.data('start-route-id', route_id);
      ui.item.data('start-index', ui.item.index() );
    };

    $scope.dragEnd = function(e, ui) {
        var start_route_id = ui.item.data('start-route-id');
        var start_route = $scope.find_route( start_route_id );
        var start_index = ui.item.data('start-index');

        var end_route_id = $($(ui.item[0]).closest('.route')).data('id');
        var end_route = $scope.find_route( end_route_id );
        var end_index = ui.item.index();

        if( start_route !== undefined && end_route !== undefined && $scope.events[e.timeStamp] === undefined )
        {
          wpt = start_route.waypoints.splice( start_index, 1)[0];
          end_route.waypoints.splice( end_index, 0, wpt);

          $scope.events[e.timeStamp] = {start_route: start_route, end_route: end_route, waypoint: wpt};


          $scope.shuffle_route( $scope.shuffle_data([start_route, end_route]));
        }
    };

    function set_up_sorting()
    {
      $('.waypoints').sortable({
          items: '.waypoint',
          connectWith: '.waypoints',
          start: $scope.dragStart,
          update: $scope.dragEnd
      });
    };

    $scope.finalize = function( route )
    {
      $http.post("/routes/" + route.id + "/finalize.json").then(
        function(response)
        {
          $scope.load_routes()
        }
      )
    }

    $scope.use_route = function(resource){
      let route = $scope.find_route(resource.id);
      route.waypoints = resource.waypoints;
      route.max_duration = resource.max_duration;
      route.total_duration = resource.total_duration;
      route.awesome = resource.awesome;
      route.name = resource.name;

      $scope.mapHelper.remove(route.outline);
      $scope.mapHelper.outlineRoute(route, true, {})
    };

    function updateMap () {
      $scope.$apply();
      $scope.load_routes();
    }

    $scope.$watch('superzone', updateMap);
    $scope.$watch('date', updateMap);
  }
]);
