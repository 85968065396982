import React from 'react';

export default (props) => {
  let line1 = props.location.street1 || "";
  let line2 = props.location.street2 || "";
  let line3 = "";
  let line4 = null;

  var renderCrossStreet = function () {
    if (line4) {
      return(
        <div>{line4}</div>
      )
    } else {
      return(null)
    }
  }

  if(props.location.city && props.location.state)
  {
    line3 = props.location.city + ', ' + props.location.state;
  }

  if(props.location.zipcode && props.location.zipcode.length > 4 && line3.length > 1)
  {
    line3 = line3 + " ";
  }

  if(props.location.zipcode)
  {
    line3 = line3 + props.location.zipcode;
  }

  if(props.showCrossStreet) {
    line4 = props.location.cross_street;
  }

  if(line1.length > 0 || line2.length > 0 || line3.length > 0)
  {
    return(
      <div className="saw-Address">
        <div>{line1}</div>
        <div>{line2}</div>
        <div>{line3}</div>
        {renderCrossStreet()}
      </div>
    );
  }
  else
  {
    return(null)
  }
}

