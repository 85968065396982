import React from 'react';

export default class CheckboxInput extends React.Component {
  // params:
  // * label
  // * name
  // * value
  // * required
  // * autocomplete: "off" or nothing
  // * onChange: method
  // * changeDelay (ms): how long to wait for additional input before firing an onChange event. Defaults to 500ms
  // * bootstrap: boolean. Add labels and other bootstrappy-things.
  //
  // Ref: https://gist.github.com/krambertech/76afec49d7508e89e028fce14894724c
  constructor(props) {
    super(props);
    this.state = {value: !!this.props.value};
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // https://stackoverflow.com/questions/28889826/react-set-focus-on-input-after-render
    if(this.props.focus)
      this.input_control.focus();
  }

  componentDidUpdate(prevProps, prevState)
  {
    let originalDefaultValue = prevProps.value;
    let currentDefaultValue = this.props.value;

    let externalChange = originalDefaultValue != currentDefaultValue;

    if(externalChange)
    {
      this.setState({value: currentDefaultValue});
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});

    if(this.props.onChange)
    {
      let prop_name = this.props.name;
      let new_value = event.target.checked;

      this.props.onChange(prop_name, new_value);
    }
  }

  render() {
    if(this.props.bootstrap == true || this.props.bootstrap === undefined)
    {
      return (
        <div className="control-group string">
          <div className="controls">
            <label className="checkbox">
              <input type="checkbox" id={this.props.name} name={this.props.name}
                 checked={this.state.value}
                 onChange={this.handleChange} tabIndex={this.props.tabindex}
                 ref={(input) => {this.input_control = input; }} />
              {this.props.label}
            </label>
            { this.renderHint(this.props.hint) }
          </div>
        </div>
      );
    }
    else
    {
      return (
        <label className="checkbox">
          <input type="checkbox" id={this.props.name} name={this.props.name}
               checked={this.state.value} onChange={this.handleChange}
               tabIndex={this.props.tabindex}
               ref={(input) => {this.input_control = input; }} />
          {this.props.label}
        </label>
      )
    }
  }

  renderHint(hint)
  {
    if(hint)
      return(
        <p className="help-block">Please leave this BLANK instead of entering invalid addresses.</p>
      )
    else
      return null;
  }
}
