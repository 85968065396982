app.controller('TodosListCtrl', [
  '$scope', '$http', '$routeParams', 'fixRailsSerializer', 'Todo',
  function($scope, $http, $routeParams, fixRailsSerializer, Todo) {
    $scope.refresh = function(ignoreCache) {
      Todo.query({ ignore_cache: ignoreCache }).then(
        function(resource) {
          $scope.todos = resource;
        }
      );
    };

    $scope.refresh();
  }
]);
