import React from 'react';
import PropTypes from 'prop-types';

import BootstrapControlWrapper from './BootstrapControlWrapper';

// Ref: https://gist.github.com/krambertech/76afec49d7508e89e028fce14894724c

export default class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,         //optional, defaults to 'text'
    name: PropTypes.string,
    error: PropTypes.array,
    required: PropTypes.bool,
    autocomplete: PropTypes.string, //  "off" or nothing
    onChange: PropTypes.func,
    changeDelay: PropTypes.number,  // (ms): how long to wait for additional
                                    // input before firing an onChange event.
                                    // Defaults to 500ms

    bootstrap: PropTypes.bool       // Add labels and other bootstrappy-things.
  }

  constructor(props) {
    super(props);
    this.state = {value: this.props.value || '', oldValue: this.props.value || ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    // https://stackoverflow.com/questions/28889826/react-set-focus-on-input-after-render
    if(this.props.focus)
      this.input_control.focus();
  }

  componentDidUpdate(prevProps, prevState)
  {
    let originalDefaultValue = prevProps.value;
    let currentDefaultValue = this.props.value;

    let externalChange = originalDefaultValue != currentDefaultValue;

    if(externalChange)
    {
      this.setState({value: currentDefaultValue}, function () { this.handleChangedValue(false) });
    }
  }

  handleChange(event) {
    clearTimeout(this.timer);
    this.setState({ value: event.target.value }, function () { this.handleChangedValue(true) })
  }

  handleChangedValue(notifyOnChange) {
    if(this.props.onChange)
    {
      let prop_name = this.props.name;

      if(this.state.value !== this.state.oldValue) {
        this.timer = setTimeout(() => {
          this.timer = null;
          this.setState({ oldValue: this.state.value }, () => {
            if(notifyOnChange) {
              this.props.onChange(prop_name, this.state.value)
            }
          });
        }, this.props.changeDelay || 0)
      }
    }
  }

  handleBlur(event) {
    let thereIsAnUnhandledChange = !!this.timer && this.state.value !== this.state.oldValue

    if(thereIsAnUnhandledChange)
    {
      clearTimeout(this.timer);

      let newValue = event.target.value;
      this.setState({value: newValue}, () => {
        if(this.props.onChange) {
          this.setState({ oldValue: this.state.value }, () => {
            this.props.onChange(this.props.name, newValue);
          });
        } else {
          this.setState({ oldValue: this.state.value });
        }
      });
    }
  }

  renderInput() {
    let _type = this.props.type || 'text'

    return (
      <input type={_type}
             id={this.props.name}
             name={this.props.name}
             value={this.state.value}
             onChange={this.handleChange}
             onBlur={this.handleBlur}
             autoComplete={this.props.autocomplete}
             size={this.props.size}
             tabIndex={this.props.tabindex}
             required={this.props.required ? 'required' : 'false'}
             ref={(input) => {this.input_control = input; }}
             data-lpignore={true}
             className={this.props.inputClass} />
    )
  }

  render() {
    if(this.props.bootstrap == true || this.props.bootstrap === undefined)
    {
      return(
          <BootstrapControlWrapper name={this.props.name} label={this.props.label}
            required={this.props.required} hint={this.props.hint} error={this.props.error} >
            {this.renderInput()}
          </BootstrapControlWrapper>
      )
    }
    else
    {
      return( this.renderInput() )
    }
  }
}
