app.controller('RouteWaypointsCtrl', [
  '$scope', '$controller', '$routeParams', '$location', '$timeout', '$filter', 'fixRailsSerializer', 'Route', 'Appointment',
  function($scope, $controller, $routeParams, $location, $timeout, $filter, fixRailsSerializer, Route, Appointment ) {
    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    $controller('RouteSharedCtrl', { $scope: $scope, Route: Route });

    $scope.dragStart = function(e, ui) {
      ui.item.data('start', ui.item.index());
    }

    $scope.dragEnd = function(e, ui) {
      var start = ui.item.data('start')
      var end = ui.item.index();

      $scope.waypoints.splice(end, 0,
      $scope.waypoints.splice(start, 1)[0]);

      $scope.$apply();
      $scope.shuffle_route($scope.shuffle_data([$scope.route]));
    }

    // Return a helper with preserved width of cells
    fixHelper = function(e, ui) {
      ui.children().each(
        function()
        {
          $(this).width($(this).width());
        }
      );

      return ui;
    };

    $scope.use_route = function(route)
    {
      $scope.mapHelper.reset().then(function() {
        $scope.route = route;
        $scope.waypoints = $scope.route.waypoints;
        $scope.mapHelper.outlineRoutes([$scope.route], true, {});

        $scope.$apply(function () {
          $scope.toggleSortable();
        });
      })
    }

    $scope.approve = function() {
      // Why Route.$post rather than $.ajax? Route.$post
      // enables the 'api_notices' thing, which shows that
      // we're working on something ... rather than the user
      // constantly waiting.
      Route.$get('/routes/' + $scope.route.id + '/approve.json').then(function(route) {
        $scope.use_route( route );
      });
    }

    $scope.close = function()
    {
      Route.$post('/routes/' + $scope.route.id + '/finalize.json').then(function(route) {
        $scope.use_route( route );
      });
    }

    $scope.open = function()
    {
      Route.$post('/routes/' + $scope.route.id + '/open.json').then(function(route) {
        $scope.use_route( route );
      });
    }

    $scope.optimize = function() {
      alert('Temporarily Disabled due to Pickups feature.');

      // Why Route.$post rather than $.ajax? Route.$post
      // enables the 'api_notices' thing, which shows that
      // we're working on something ... rather than the user
      // constantly waiting.
      /*
      Route.$post('/routes/' + $scope.route.id + '/optimize.json').
      then(
        function(route)
        {
          $scope.use_route( route );
        }
      ); */
    }

    $scope.reverse = function() {
      alert('Temporarily Disabled.');

      // $.ajax({
      //   url:'/routes/' + $scope.route.id + '/reverse.json',
      //   type: 'POST',
      //   success: function(resource) {
      //     $scope.use_route( resource.route );
      //     $scope.$apply();
      //   }
      // });
    }

    $scope.reserve = function() {
      $.ajax({
        url:'/routes/' + $scope.route.id + '/reserve.json',
        type: 'POST',
        success: function(resource) {
          $scope.use_route( resource.route );
          $scope.$apply();
        }
      });
    }

    $scope.timeframes = function() {
      window.location.href = '/routes/' + $scope.route.id + '/timeframes'
    }

    $('.waypoints tbody').sortable({
        // NOTE: first-child and last-child are NOT
        // compatible with < IE9 per
        // stackoverflow.com/questions/9362800
        items: '> tr:not(:first-child):not(:last-child)',
        helper: fixHelper,
        start: $scope.dragStart,
        update: $scope.dragEnd
    });

    $scope.toggleSortable = function() {
      if ($scope.route.status == 'Finalized') {
        $('.waypoints tbody').sortable("disable");
      } else {
        $('.waypoints tbody').sortable("enable");
      }
    }

    Route.get($routeParams['route_id']).then(function(resource) {
      $.post('/observations.json', {
        observations: {
          identifier: 'Waypoint map',
          value: document.current_user_id || 0
        }
      });
      $scope.mapHelper.reset();
      $scope.use_route( resource );
    });
  }
]);
