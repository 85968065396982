app.controller('ServiceOrderPartsOrderCtrl', [
  '$scope', '$http', 'fixRailsSerializer', 'ServiceOrder', 'Part', 'PartOrder', 'PartOrderLine', 'PartGroup',
  function($scope, $http, fixRailsSerializer, ServiceOrder, Part, PartOrder, PartOrderLine, PartGroup ) {
    function show_part_list()
    {
      $('.add-part-modal').modal('show');
    }

    function hide_part_list()
    {
      $('.add-part-modal').modal('hide');
    }

    function load_parts()
    {
      Part.query().then( function(resource) { $scope.parts = resource; });
      PartGroup.query().then( function(resource) { $scope.part_groups = resource; });
    }

    function load_part_order()
    {
      if( $scope.service_order && $scope.service_order.part_order_id && !$scope.part_order )
      {
        PartOrder.get($scope.service_order.part_order_id).then(function(resource)
        {
          $scope.part_order = resource;
        });
      }
    }

    $scope.$watch('service_order', load_part_order);

    $scope.new_part = function()
    {
      $scope.part_order_requested = true;

      if( $scope.parts === undefined )
      {
        load_parts();
      }

      if( $scope.part_order === undefined )
      {
        $scope.part_order = new PartOrder({client_id: $scope.service_order.client_id, customer_id: $scope.service_order.customer_id});

        $scope.part_order.save().then(function(part_order)
        {
          $scope.part_order = part_order;
          $scope.service_order.part_order_id = part_order.id;
          $scope.service_order.update();
          show_part_list();
        });
      }
      else
      {
        show_part_list();
      }
    }

    $scope.set_part = function(part, price_each)
    {
      var pol = new PartOrderLine({part_order_id: $scope.part_order.id, part_id: part.id, description: part.description || part.sku, quantity: 1, price_each: price_each});
      pol.save();

      $scope.part_order.lines = $scope.part_order.lines || [];
      $scope.part_order.lines.push( pol );
      hide_part_list();
    }

    $scope.should_show_part = function( part )
    {
      return( (!$scope.selected_part_group) || $scope.selected_part_group.part_ids.indexOf( part.id ) > -1 );
    }

    $scope.remove_part = function( po_line )
    {
      i = $scope.part_order.lines.indexOf( po_line );
      po_line.delete().then(function()
      {
        $scope.part_order.lines.splice(i, 1);
      });
    }

    $scope.total = function()
    {
      if($scope.service_order && $scope.service_order.part_order)
      {
        var ttl = 0.0;
        angular.forEach( $scope.service_order.part_order.lines, function(line) { ttl = ttl + (line
    .price_each * line.quantity); });
        return(ttl);
      }
      else
      {
        return(0.0);
      }
    }
  }
]);
