function hideTextMessageActions(elem) {
  var id = $(elem).attr('data-text-message-id');
  var table = $(elem).closest('table');

  $('[data-text-message-actions-row=' + id + ']', table).addClass('display-none');
  $(elem).html('Show Actions');
  $(elem).attr('data-text-message-actions-opened', false);
}

function showTextMessageActions(elem) {
  var id = $(elem).attr('data-text-message-id');
  var table = $(elem).closest('table');

  $('[data-text-message-actions-row=' + id + ']', table).removeClass('display-none');
  $(elem).html('Hide Actions');
  $(elem).attr('data-text-message-actions-opened', true);
}

$(document).ready(function () {
  $(document).on('click', '[data-text-message-actions]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-text-message-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-text-message-id');
      var otherId = $(otherElem).attr('data-text-message-id');

      if (id != otherId) {
        hideTextMessageActions(otherElem);
      }
    });

    if($(elem).attr('data-text-message-actions-opened') == 'true') {
      hideTextMessageActions(elem);
    } else {
      showTextMessageActions(elem);
    }
  });
});
