// stackoverflow.com/questions/11850025/recommended-way-of-getting-data-from-the-server/11850027#11850027

app.factory('AssignmentGrid', [
  'fixRailsSerializer', 'Calendar', 'Day', 'Employee', 'Assignment', 'AssignmentNote', '$filter', '$http',
  function(fixRailsSerializer, Calendar, Day, Employee, Assignment, AssignmentNote, $filter, $http) {
    var AssignmentGrid = function(attributes) {
      angular.extend(this, attributes);

      this.employees = [];
      this.days = [];

      for (var i = 0; i < 7; i++) {
        this.days[i] = Calendar.addDate(this.week_of, i);
      }
    };

    AssignmentGrid.prototype.parse_rows = function(rows, status) {
      var employees = [];
      var time = Date.now();
      // rows = rows.rows
      var grid = this;
      angular.forEach(rows, function(row) {
        var employee = new Employee({id: row.id, name: row.name, cell_phone: row.cell_phone});
        // initialize schedule
        employee.schedule = {};
        angular.forEach(grid.days, function(day) {
          var date_key = $filter('date')(day, 'yyyy-MM-dd');
          employee.schedule[date_key] = new Day({date: day, employee_id: employee.id});
        });

        angular.forEach(row.assignments, function(assignment) {
          grid.remember_assignment(employee, assignment);
        });

        angular.forEach(grid.days, function(day) {
          const date_key = $filter('date')(day, 'yyyy-MM-dd');
          const schedule = employee.schedule[date_key];
          const is_working_day = _.includes(row.working_days, day.getDay());

          schedule.unassigned = schedule.assignments.length === 0 && is_working_day;
          schedule.is_day_off = !is_working_day;
        });

        angular.forEach(row.assignment_notes, function(note) {
          grid.remember_note(employee, note);
        });

        employees.push(employee);
      });

      this.employees = $filter('orderBy')(employees, 'name');
    };

    AssignmentGrid.prototype.query_params = function() {
      var params = {};
      params['team'] = this.team;
      params['start_date'] = $filter('date')(this.days[0], 'yyyy-MM-dd');
      params['end_date'] = $filter('date')(this.days[6], 'yyyy-MM-dd');

      if (this.team === 'Technicians' && this.superzone.id !== 0) {
        params['superzone_id'] = this.superzone.id;
      }

      return params;
    };

    AssignmentGrid.prototype.load_employees = function() {
      var grid = this;
      $http.get('/assignments/grid.json', {params: grid.query_params()})
        .then(function(response) {
          grid.parse_rows(response.data);
        });
    };

    AssignmentGrid.prototype.remember_note = function(employee, assignment_note) {
      var schedule = employee.schedule[assignment_note.date];

      if (schedule === undefined) {
        console.log('Cannot find a day for this employee: ' + assignment_note.date);
        console.log(employee.schedule);
      } else {
        schedule.note = new AssignmentNote(assignment_note);
      }
    };

    AssignmentGrid.prototype.remember_assignment = function(employee, assignment) {
      var day = employee.schedule[assignment.date];

      if (day === undefined) {
        console.log('Cannot find a day for this employee: ' + assignment.date);
        console.log(employee.schedule);
      } else {
        day.assignments.push(new Assignment(assignment));
      }
    };

    return AssignmentGrid;
  }
]);
