import React from 'react';

export default class MultiPictureStatusComponent extends React.Component {
  render()
  {
    if( this.props.picture_count > 0 && this.props.picture_count == this.props.uploaded_picture_count )
      return( <h1 className='text-success text-large'>Finished</h1>)

    else if( this.props.picture_count > 0 )
      return(
        <div className="alert alert-warning">
          <h1 className='text-warning text-large'>Uploading. Please wait.</h1>
          <h2>Please DO NOT leave this page while it is working.</h2>
        </div>
      )

    else
      return(null)
  }
}
