function customers(state=[], action=null)
{
  switch( action.type )
  {
    case 'CUSTOMER_SEARCH_POPULATE':
      return action.customers || [];

    case 'CUSTOMER_SEARCH_RESET':
      return null;

    default:
      return state
  }
}

function notes(state=[], action=null)
{
  switch( action.type )
  {
    case 'CUSTOMER_NOTES_POPULATE':
      return action.notes;

    case 'CUSTOMER_RESET':
      return null;

    default:
      return state
  }
}
function customer(state=null, action=null)
{
  switch( action.type )
  {
    case 'CUSTOMER_POPULATE':
      return action.customer || [];

    case 'CUSTOMER_RESET':
      return null;

    default:
      return state
  }
}

function services(state=null, action=null)
{
  switch( action.type )
  {
    case 'SERVICES_POPULATE':
      return action.services || [];

    case 'CUSTOMER_RESET':
      return [];

    default:
      return state
  }
}

function feature_flags(state, action)
{
  switch( action.type )
  {
    case 'ALLOW_NEW_CUSTOMER':
      if(state.indexOf('NEW_CUSTOMER_BUTTON') == -1)
      {
        return [...state, 'NEW_CUSTOMER_BUTTON']
      }
      else
      {
        return state;
      }
    default:
      return state;
  }
}

function entities(state, action)
{
  return {
    customers: customers(state.customers, action),
     customer: customer(state.customer, action),
     services: services(state.services, action),
        notes: notes(state.notes, action),
  }
}

const defaultState = {
  entities: {
    customers: null,
    customer: null,
    services: [],
    notes: [],
  },
  feature_flags: []
}

export default function ServiceReducer(state=defaultState, action=null)
{
  if(!state)
    state = defaultState;

  if(!state.entities)
    state.entities = defaultState.entities;

  if(!state.feature_flags)
    state.feature_flags = []

  return(
    {
      feature_flags: feature_flags(state.feature_flags, action),
      entities: entities(state.entities, action)
    }
  )
}
