app.controller('PlanRouteCtrl', [
  '$scope', '$route', '$routeParams', '$location', '$q', '$filter', 'fixRailsSerializer', 'Route', 'Client', 'Superzone', 'ServiceOrder', 'Appointment',
  function($scope, $route, $routeParams, $location, $q, $filter, fixRailsSerializer, Route, Client, Superzone, ServiceOrder, Appointment ) {
    $scope.initializeDateFromParams = function()
    {
      var selected_date = $location.search()['date'];
      if( selected_date )
      {
        $scope.date = selected_date;
      }

      $scope.date_ok = true;
    }

    initializeSuperzoneFromParams = function()
    {
      var sid = $location.search()['superzone_id'];
      if( sid )
      {
        angular.forEach($scope.selectable_superzones, function(s)
        {
          if( s.id == sid )
          {
            $scope.superzone = s;
          }
        });
      }

      $scope.superzone_ok = true;
    }

    function initializeClientFromParams(){
      var client_id = $location.search()['client_id'];

      if(client_id){
        $scope.client = $filter('getById')($scope.selectable_clients, client_id );
      } else {
        $scope.client = $scope.selectable_clients[0]
      }

      $scope.client_ok = true;
    }

    function loadUndated() {
      return new Promise(function(resolve, reject) {
        $.get('/undated_service_orders.json', { superzone_id: $scope.superzone.id, client_id: $scope.client && $scope.client.id }).then(function(resources) {
          $scope.undated = resources.service_orders;

          if ($scope.undated && $scope.undated.length > 0) {
            $scope.mapHelper.addServiceOrders($scope.undated, { clickable: true, onClick: onServiceOrderClick, showTooltip: true })
          }
          $scope.$apply();
          resolve();
        });
      });
    }

    function setLoaded() {
      $scope.loadingRoutesAndServiceOrders = false;
      $scope.$apply();
    }

    function onServiceOrderClick (event, service_order) {
      if( service_order.selected )
      {
        index = $scope.selected_service_orders.indexOf(service_order);
        $scope.selected_service_orders.splice(index, 1);
        service_order.selected = false;
      }
      else
      {
        service_order.selected = true;
        $scope.selected_service_orders.push(service_order);
      }
      $scope.$apply();
    }

    function onRouteClick (event, route) {
      $scope.setEditable(route);
      $scope.$apply();
    }

    function ready_to_draw()
    {
      return $scope.superzone_ok && $scope.client_ok && $scope.date_ok;
    }

    function set_parameters()
    {
      $location.search('superzone_id', $scope.superzone.id);
      $location.search('date', $scope.date);

      if ($scope.client && $scope.client.id) {
        $location.search('client_id', $scope.client.id)
      };
    }

    function draw()
    {
      if (!ready_to_draw()) {
        return;
      }

      $scope.mapHelper.reset().then(function () {
        $scope.undated = [];
        $scope.routes = [];
        $scope.$apply();
        load_routes_and_undated()
      });
    }

    function updateMap()
    {
      $scope.loadingRoutesAndServiceOrders = true;
      $scope.$apply()

      if( $scope.superzone && $scope.date )
      {
        set_parameters();
        draw();
      } else {
        setLoaded();
      }
    }

    function load_routes_and_undated() {
      Promise.all([loadUndated(), loadRoutes()]);
    }

    function loadRoutes() {
      return new Promise(function(resolve, reject) {
        Route.$get('/route_search', { superzone_id: $scope.superzone.id, date: $scope.date }).then(function(resource) {
          $scope.routes = resource.route_searches;
          if ($scope.routes && $scope.routes.length > 0) {
            $scope.mapHelper.outlineRoutes($scope.routes, false, { onClick: onRouteClick }).then(function () {
              $scope.$apply();
            });
          }

          setLoaded();
        });

        $scope.$apply();
      });
    };

    $scope.update_route = function()
    {
      if ($scope.selected_service_orders.length > 0) {
        $scope.scheduling = true;
        $scope.scheduledAppointments = 0;

        var promises = $scope.selected_service_orders.map(function(service_order) {
          return create_appointment(service_order, $scope.editable_route).then(function () {
            $scope.scheduledAppointments += 1
          }, function () {
            $scope.scheduledAppointments += 1
          })
        });

        $q.all(promises).then(function () {
          after_scheduling_appointments();
        }, function() {
          after_scheduling_appointments();
        })
      }
    }

    function after_scheduling_appointments() {
      $scope.scheduling = false;
      updateMap();
    }

    function create_appointment( service_order, route )
    {
      var appointment = new Appointment({
        route_id: route.id,
        service_order_id: service_order.id,
        location_id: service_order.customer.location.id,
        long_lead_time_reason: 'Planner'
      });

      return( appointment.save() );
    }

    $scope.setEditable = function( route )
    {
      if( $scope.editable_route )
      {
        $scope.editable_route.editing = false;
        $scope.editable_route = undefined;
      }

      route.editing = true;
      $scope.editable_route = route;
    }

    if (document.current_user_id) {
      $.post('/observations.json', { observations: { identifier: 'Route Planner Map', value: document.current_user_id } });
    }

    $scope.events = [];
    $scope.scheduling = false;
    $scope.loadingRoutesAndServiceOrders = false;
    $scope.selected_service_orders = [];
    $scope.route_outline_index = 0;
    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    $scope.$watch('superzone', updateMap);
    $scope.$watch('date', updateMap);
    $scope.$watch('client', updateMap);

    Superzone.query({status: 'In Service'}).then(
      function(resources) {
        $scope.selectable_superzones = resources;
        initializeSuperzoneFromParams();
      }
    );

    Client.query().then(
      function(resources) {
        $scope.selectable_clients = resources;
        $scope.selectable_clients.unshift(new Client({name: 'All'}));
        initializeClientFromParams();
      }
    );

    $scope.initializeDateFromParams();
  }
]);
