import lodash from 'lodash';
import {geocodeWithMQ} from './mq';

let sufficientStreet = function(location)
{
  return(location.street1 && location.street1.length >= 6);
}

let getCityFromZip = function(atts)
{
  let success = (response) => {
    let zip = response[0];

    if(zip) {
      if(sufficientStreet(atts.location))
      {
        let changes = { city: zip.city, state: zip.state }
        let new_location = Object.assign({}, atts.location, changes);
        atts.location = new_location;
        geocode(atts)

      } else {
        atts.onChanges({
          city: zip.city,
          state: zip.state,
          latitude: null,
          longitude: null,
          geocoderResponse: {
            error: 'Please, review the street'
          }
        });
      }
    } else {
      atts.onChanges({
        latitude: null,
        longitude: null,
        geocoderResponse: {
          error: 'Could not find this zipcode'
        }
      });
    }
  }

  let failure = (response) => {
     atts.onChanges({
      latitude: null,
      longitude: null,
      geocoderResponse: {
        error: 'Failed to geocode this zipcode'
      }
    });
    window.f = response;
  }

  $.get('/zipcodes/search.json?code=' + atts.location.zipcode).then(success, failure)
}

let getMatches = (locations) => { return( locations.filter((l) => {return(l.quality == 'match')})) }
let getSuggestions = (locations) => { return(locations.filter((l) => {return(l.quality == 'suggestion')}))}
let getFuzzies = (locations) => { return( locations.filter((l) => {return(l.quality == 'fuzzy')})) }
let getNonBad = (locations) => { return( locations.filter((l) => {return(l.quality != 'bad')})) }

let geocode = function(atts)
{

  let success = (locations) =>
  {
    locations = getNonBad(locations);
    let matches = getMatches(locations);
    let error = null;

    if(locations.length == 0)
    {
      error = 'Could not find this street';
    }

    if (error === null) {
      if (atts.location) {
        if (typeof atts.location.city === 'undefined' || atts.location.city === null || atts.location.city.length === 0) {
          error = 'Please, provide a valid city';
        } else {
          if (typeof atts.location.state === 'undefined' || atts.location.state === null || atts.location.state.length !== 2) {
            error = 'Please, provide a valid state';
          }
        }
      }
    }

    if (error) {
      atts.onChanges({
        latitude: null,
        longitude: null,
        status: null,
        geocoderResponse: {
          error: error
        }
      });
      atts.onMultipleMatches(locations);
    } else {
      atts.onChanges({ latitude: null, longitude: null, status: null });
      atts.onMultipleMatches(locations);
    }
  }

  let failure = (error) => {
    atts.onChanges({
      latitude: null,
      longitude: null,
      geocoderResponse: {
        error: error
      }
    });
  }

  atts.onMultipleMatches([]);
  geocodeWithMQ(atts).then(success, failure);
}

let potentiallyGeocode = function(atts)
{
  let error = null;
  let skip = true;

  if(atts.change == 'zipcode') {
    if (atts.location && atts.location.zipcode) {
      if (atts.location.zipcode.length == 5) {
        skip = false;
        getCityFromZip(atts);
      } else {
        error = 'Please, provide a zipcode with 5 digits'
      }
    } else {
      error = 'Please, provide a zipcode'
    }
  }

  if (atts.change == 'street1') {
    if (atts.location && atts.location.street1) {
      if (atts.location.street1.length >= 6) {
        geocode(atts);
      } else {
        error = 'Please, provide a street with 6 characters'
      }
    } else {
      error = 'Please, provide a street'
    }
  }

  if (atts.change == 'city') {
    skip = false;
    geocode(atts);
  }

  if (atts.change == 'state') {
    skip = false;
    geocode(atts);
  }

  if (error) {
    skip = false;
    atts.onChanges({ latitude: null, longitude: null, geocoderResponse: { error: error } });
  }

  if (skip && atts.skip) {
    atts.skip();
  }
}

export {potentiallyGeocode, getMatches, getFuzzies, getSuggestions};
