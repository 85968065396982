import React from 'react';
import receiveMultiPictureThunk from '../../thunks/receiveMultiPictureThunk';

export default class MultiPictureInputComponent extends React.Component {
  render() {
    return (
      <label htmlFor="photo-input" className="btn btn-primary btn-large">Take Picture
        <div style={{'font-size': '2px'}}>
          <input type="file" id="photo-input" capture="camera" accept="image/" onChange={this.handleLoadEvent.bind(this)} style={{display: 'none'}} />
        </div>
      </label>
    )
  }

  handleLoadEvent(event)
  {
    let input = event.target;

    if( input.files && input.files[0])
    {
      let reader = new FileReader();
      reader.onload = (event) => {
        this.props.processFile(
                                    this.props.appointment_id,
                                    event.target.result
                                 )};

      reader.readAsDataURL(input.files[0]);
    }
  }
};
