app.controller('RouteMapCtrl', [
  '$scope', '$http', '$timeout', '$routeParams', 'fixRailsSerializer', 'Calendar', 'Appointment', 'Route', 'Superzone',
  function($scope, $http, $timeout, $routeParams, fixRailsSerializer, Calendar, Appointment, Route, Superzone) {
    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    initialize();

    $scope.$watch('view', setTheStage);

    function filter_start( )
    {
      if( $scope.route && $scope.filter_start )
      {
        route_date = Calendar.stringToDate($scope.route.date);
        var time = Calendar.stringToTime($scope.filter_start, route_date);

        return(Calendar.dateToString( time, 'ymdhms'));
      }
      else
      {
        return(false);
      }
    }

    function filter_end( )
    {
      if( $scope.route && $scope.filter_end )
      {
        route_date = Calendar.stringToDate($scope.route.date);
        var time = Calendar.stringToTime($scope.filter_end, route_date);

        return(Calendar.dateToString( time, 'ymdhms'));
      }
      else
      {
        return(false);
      }
    }

    function setTheStage()
    {
      var starting_x = $('#appointments').position().top;
      $('.route-details').attr('style', 'height: calc( 100vh - ' +  starting_x + 'px');

      $scope.removeUndated();

      if( $scope.view == 'available' )
      {
        $scope.undated = $scope.mapHelper.addServiceOrders($scope.available_service_orders, { clickable: false, showTooltip: false });
      }
    }

    $scope.removeUndated = function() {
      return new Promise(function(resolve, reject) {
        if ($scope.undated) {
          $scope.undated.forEach(function(so) {
            $scope.mapHelper.remove(so);
          });
        }

        resolve();
      });
    }

    function loadRoute( route_id )
    {
      return(Route.get($routeParams.route_id));
    }

    function receiveAvailableServiceOrders( response )
    {
      $scope.available_service_orders = response.data;
      angular.forEach( $scope.available_service_orders, function( so, index )
      {
        so.marker = index + 1;
      });

      setTheStage();
    }

    function loadAvailableServiceOrders()
    {
      $http.get('/routes/' + $scope.route.id + '/available_service_orders.json').then(receiveAvailableServiceOrders);
    }

    $scope.consider_refresh = function()
    {
      if($scope.is_today == true)
      {
        $scope.refresh_map();
        $scope.load_waypoint_details();

        countdown();
      }
    }

    $scope.load_waypoint_details = function()
    {
      angular.forEach($scope.route.waypoints, function(wpt)
      {
        url = '/routes/' + $scope.route.id + '/waypoints/' + wpt.position + '.json';
        $http.get(url).then(waypoint_update_callback( wpt ));
      })
    }

    function waypoint_update_callback( wpt )
    {
      return(
        function( result )
        {
          wpt.outcome = result.data.outcome;
          wpt.status = result.data.status;
        }
      )
    }

    $scope.refresh_map = function()
    {
      $scope.refreshing = true;

      $scope.removeUndated().then(function () {
        loadAvailableServiceOrders();
        $scope.mapHelper.remove($scope.route.outline);

        loadRoute( $routeParams.route_id ).then( function(route)
        {
          $scope.route = route;
          $scope.mapHelper.outlineRoute($scope.route, true, {});
          $scope.refreshing = false;
        });
      })
    }

    function countdown()
    {
      var five_minutes_in_microseconds = 5 * 60 * 1000;
      $timeout( function(){ $scope.consider_refresh() }, five_minutes_in_microseconds );
    }

    function initialize()
    {
      $scope.view = 'appointments';

      if( $routeParams.route_id !== undefined )
      {
        loadRoute( $routeParams.route_id ).then( function(route)
        {
          $scope.route = route;
          loadAvailableServiceOrders();
          $scope.is_today = (Calendar.dateToString(Calendar.today(), 'ymd') == route.date);
          $scope.is_past = (Calendar.stringToDate(route.date) < Calendar.today());

          $scope.mapHelper.outlineRoute(route, true, {});
          $scope.load_waypoint_details();
        });
      }

      countdown();
    }

    $scope.finalize = function( route )
    {
      $http.post("/routes/" + route.id + "/finalize.json").then(
        function(response)
        {
          $scope.route = response.data.route;
        }
      )
    }

    $scope.schedule = function(input)
    {
      $scope.refreshing = true;
      $scope.appointment = undefined;
      var prospect = undefined;

      if( input.service_order_id ) // came from long lead time modal
      {
        prospect = input;
      }
      else   // came from sidebar
      {
        prospect = new Appointment({service_order_id: input.id, route_id: $scope.route.id})
      }

      prospect.create().then(function( appointment )
        {
          if( appointment.long_lead_time_reasons )
          {
            $scope.refreshing = false;
            $scope.long_lead_time_reasons = appointment.long_lead_time_reasons;

            $scope.appointment = appointment;
            $scope.appointment.long_lead_time_reasons = undefined;
          }
          else
          {
            $scope.refresh_map();
            $scope.load_waypoint_details();

            countdown();
          }
        }
      );
    }
  }
]);
