import React from 'react';

export default class MultiPictureReturnLink extends React.Component {
  render()
  {
    let service_order_path = "/service_orders/" + this.props.service_order_id;

    return(
      <a href={service_order_path}
         disabled={!this.linkActive()}
         className={"btn btn-large btn-default"}>Return To Service Order</a>
        )
  }

  linkActive()
  {
    return( this.props.picture_count == this.props.uploaded_picture_count )
  }
}
