app.controller('NewPreQCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Customer', 'Client', 'PreQ',
  function($scope, $routeParams, $location, fixRailsSerializer, Customer, Client, PreQ ) {
    $scope.store_id = document.current_user_store_id;
    $scope.store_rep = $scope.store_id != undefined;

    Client.query().then(function(res){
      $scope.clients = res
      if($scope.store_rep)
      {
        $scope.preq.client = $scope.clients[0];
      }
    });

    $scope.preq = {customer_id: $routeParams['customer_id']};

    $scope.save_preq = function()
    {
      function visit_preq_items_page(preq)
      {
        $location.path('/pre_qs/' + preq.id + '/items');
      }

      var preq = new PreQ($scope.preq);
      preq.client_id = preq.client.id
      delete preq.client
      preq.create().then( visit_preq_items_page )
    }
  }
]);
