import React from 'react';

export default (props) => {
  return(
    <div className="saw-Loading">
      <h1>Loading...</h1>
    </div>
  );
}

