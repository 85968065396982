app.controller('PreQItemsCtrl', [
  '$scope', '$routeParams', 'fixRailsSerializer', 'PreQ', 'PreQItem', 'FurnitureDescriptions',
  function($scope, $routeParams, fixRailsSerializer, PreQ, PreQItem, FurnitureDescriptions) {
    $scope.popular_descriptions = FurnitureDescriptions;
    $scope.parent_description = "PreQ";

    PreQ.get( $routeParams['pre_q_id'] ).then(function( resource )
    {
      $scope.pre_q = resource;
      $scope.parent_url = "/pre_qs/" + $routeParams['pre_q_id'];
      $scope.items = $scope.pre_q.items;
      $scope.furniture = {issues: [{}], quantity: 1, pre_q_id: $scope.pre_q.id };
    });

    $scope.issue_submitted = function()
    {
      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue.description !== "" && last_issue.description !== undefined )
      {
        $scope.furniture.issues.push({});
      }
    }

    $scope.save_item = function()
    {
      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue.description !== "" && last_issue.description !== undefined )
      {
        i = $scope.furniture.issues.indexOf( last_issue );
        $scope.furniture.issues.splice(i, 1);
      }

      var data = $scope.furniture;
      var item = new PreQItem( data );

      if( item.id !== undefined )
      {
        var index = $scope.items.findIndex(function(i) {
          return i.id == item.id;
        });

        item.update().then(function(data) {
          $scope.furniture = { issues: [{}], quantity: 1, pre_q_id: $scope.pre_q.id }
          $scope.items[index] = data;
        })
      }
      else
      {
        item.create().then( function()
        {
          $scope.items.push(item);
          $scope.furniture = {issues: [{}], quantity: 1, pre_q_id: $scope.pre_q.id };
        });
      }
    }

    $scope.edit_item = function( item )
    {
      $scope.furniture = item;

      last_issue = $scope.furniture.issues[ $scope.furniture.issues.length - 1];

      if( last_issue === undefined || ( last_issue.description !== "" && last_issue.description !== undefined ) )
      {
        $scope.furniture.issues.push({});
      }
    }

    $scope.delete_item = function( item )
    {
      PreQItem.$delete("/pre_q_items/" + item.id).then(
        function()
        {
          i = $scope.items.indexOf( item );
          $scope.items.splice(i, 1);
        }
      );
    }
  }
]);
