$(document).on('update', '#repair_report_container .replacement-parts-container', function( ) {
  var outcome = $('#repair_report_container #report_outcome').val();
  if( outcome == 'Parts Required' || outcome == 'Wrong Parts Sent' ) {
    $('#repair_report_container .replacement-parts-container').show( 'SlideDown' );
  } else {
    $('#repair_report_container .replacement-parts-container').hide( );
  }
});

$(document).on('update', '#repair_report_container #more_time', function() {
  var outcome = $('#repair_report_container #report_outcome').val();
  if( outcome == 'Need More Time' || outcome == 'Parts Required' || outcome == 'Wrong Parts Sent' ) {
    $('#more_time').show( 'SlideDown' );
  } else {
    $('#more_time').hide( );
    $('#time_to_complete').attr('value', null);
  }
});

$(document).on('change', '#repair_report_container #report_outcome', function() {
  $('#repair_report_container .replacement-parts-container').trigger('update');
  $('#more_time').trigger('update');
});

$(document).on('update', '#cleaning_report_container .replacement-parts-container', function( ) {
  var outcome = $('#cleaning_report_container #report_outcome').val();
  if( outcome == 'Unsuccessful' || outcome == 'Cleaning Not Performed' ) {
    $('#cleaning_report_container .replacement-parts-container').show( 'SlideDown' );
  } else {
    $('#cleaning_report_container .replacement-parts-container').hide( );
  }
});

$(document).on('update', '#cleaning_report_container #more_time', function() {
  var outcome = $('#cleaning_report_container #report_outcome').val();
  if( outcome == 'Unsuccessful' || outcome == 'Cleaning Not Performed' ) {
    $('#more_time').show( 'SlideDown' );
  } else {
    $('#more_time').hide( );
    $('#time_to_complete').attr('value', null);
  }
});

$(document).on('change', '#cleaning_report_container #report_outcome', function() {
  $('#cleaning_report_container .replacement-parts-container').trigger('update');
  $('#more_time').trigger('update');
});
