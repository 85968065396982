// docs at bootstrap-datepicker.readthedocs.org/en/latest/options.html#daysofweekdisabled

app.directive('mondaypicker', [function() {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      date: '='
    },
    link: function(scope, element, attrs) {
      element.datepicker({
        dateFormat: 'yyyy-mm-dd'
      });

      element.on('changeDate', function(ev) {
        scope.$apply(function() {
          scope.date = ev.date;
        });
      });

      scope.$watch('date', function() {
        $(element).datepicker('update', scope.date);
      });
    },
    template: '<input type="text" class="monday-picker" data-date-week-start=1 data-date-days-of-week-disabled="[2,3,4,5,6,0]" data-date-autoclose="true" class="input-small" ng-model="date"/>',
    replace: true
  };
}]);
