app.controller('TechReportCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Appointment', 'Customer', 'Client', 'ServiceOrder', 'TechReport', 'Calendar', 'TechReportService', 'IssueCauses', 'IssueUsages',
  function($scope, $routeParams, $location, fixRailsSerializer, Appointment, Customer, Client, ServiceOrder, TechReport, Calendar, TechReportService, IssueCauses, IssueUsages) {
    $scope.tech_report = {};
    $scope.appointment_id = $routeParams['appointment_id'];
    TechReportService.initialize_tech_report( $scope.appointment_id, $scope );

    $scope.show_loading_pictures = function()
    {
      return( $scope.tech_report.pictures === undefined || $scope.tech_report.pictures == null );
    }

    $scope.tech_report_state = function()
    {
      if( $scope.tech_report === undefined )
      {
        return('Waiting');
      }
      else if( $scope.tech_report.id === undefined || $scope.tech_report.id === null )
      {
        return('new');
      }
      else
      {
        return('saved');
      }
    }

    $scope.sub_report = function(fr, report)
    {
      $location.path("/appointments/" + $scope.appointment_id + "/tech_report/" + fr.id + "/" + report);
    }

    $scope.actual_time_in_hours = function()
    {
      if( $scope.tech_report && $scope.tech_report.time_in && $scope.tech_report.time_out )
      {
        time_in = Calendar.stringToTime( $scope.tech_report.time_in );
        time_out = Calendar.stringToTime( $scope.tech_report.time_out );

        an_hour = Calendar.stringToTime('4:00 AM') - Calendar.stringToTime('3:00 AM')
        delta = ( time_out - time_in ) / an_hour;
        return( delta );
      }
    }

    $scope.actual_time = function()
    {
        return( Calendar.hoursToDuration( $scope.actual_time_in_hours() ) );
    };

    $scope.save_report = function()
    {
      if( $scope.tech_report.id )
      {
        tr = new TechReport( $scope.tech_report );
        tr.update().then(TechReportService.use_tech_report);
      }
      else
      {
        tr = new TechReport( $scope.tech_report );
        tr.create().then(TechReportService.use_tech_report);
      }

    }

    $scope.persist_tech_report = function( item )
    {
      window.x = item;
      if(item.report.id)
      {
        item.report.save();
      }
      else
      {
        item.report.create();
      }
    }

    $scope.causes = IssueCauses;
    $scope.usages = IssueUsages;

    $scope.repair_outcomes = JSON.stringify(["Complete", "Customer Refused", "Not Covered by Warranty", "No Service Needed", "Wrong Parts Sent", "Parts Required", "Exchange", "Need More Time", "Inspection Complete"]);
    $scope.cleaning_outcomes = JSON.stringify(["Successful", "Unsuccessful", "Cleaning Not Performed", "Not Covered", "Inspection Complete"]);
    $scope.mattress_sizes = JSON.stringify(["Twin", "Double", "Full", "Queen", "King", "CA King"]);
    $scope.floor_types = JSON.stringify(["Rug", "Wood"]);
    $scope.support_systems = JSON.stringify(["", "Metal Frame", "Slats", "Center Rail", "Platform", "No Frame"]);
    $scope.frame_types = JSON.stringify(["Metal", "Wood", "No Frame"]);
    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
  }
]);
