$(function(){
  $(document).on('click', ".checkable", function(event)
  {
    var checkbox = $(event.target);

    var complete = $(this).prop('checked') == true;
    var params = {"complete": complete};

    $.ajax({
      url: checkbox.data('uri') + "?authenticity_token=" + AUTH_TOKEN,
      dataType: "json",
      type: 'put',
      data: JSON.stringify(params),
      contentType: "application/json",
      beforeSend: function () {
        $('.ajax-loader').removeClass('display-none');
      },
      complete: function(){
        $('.ajax-loader').addClass('display-none');
      }
    });
  });
});
