import {connect} from 'react-redux';
import CustomerSearch from '../../components/Customers/Search';
import {customerSearchThunk} from '../../thunks/customerThunk';

let mapStateToProps = (state, ownprops) => {
  return(
  {
    'customers': state.entities.customers,
    'allowAddCustomer': state.feature_flags.includes('NEW_CUSTOMER_BUTTON')
  });
}

let mapDispatchToProps = (dispatch) => {
  return {
    customerSearch: (criteria) => {
      return dispatch(customerSearchThunk(criteria));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch);
