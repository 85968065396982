function hideTodoActions(elem) {
  var id = $(elem).attr('data-todo-id');
  var table = $(elem).closest('table');

  $('[data-todo-actions-row=' + id + ']', table).addClass('display-none');
  $(elem).html('Show Actions');
  $(elem).attr('data-todo-actions-opened', false);
}

function showTodoActions(elem) {
  var id = $(elem).attr('data-todo-id');
  var table = $(elem).closest('table');

  $('[data-todo-actions-row=' + id + ']', table).removeClass('display-none');
  $(elem).html('Hide Actions');
  $(elem).attr('data-todo-actions-opened', true);
}

$(document).ready(function () {
  $('#todo-category').on('change', function (event) {
    var value = event.target.value;
    var role = null;

    if (value == "Review") {
      role = "Admin";

    } else if (value == "Route Request") {
      role = "Scheduler";
    }

    if (role) {
      var option = $('#todo-role option').filter(function () { return $(this).html() == role; }).val();
      $('#todo-role').val(option);
    }
  })

  $(document).on('click', '[data-todo-actions]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-todo-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-todo-id');
      var otherId = $(otherElem).attr('data-todo-id');

      if (id != otherId) {
        hideTodoActions(otherElem);
      }
    });

    if($(elem).attr('data-todo-actions-opened') == 'true') {
      hideTodoActions(elem);
    } else {
      showTodoActions(elem);
    }
  });

  $(document).on('ajax:beforeSend', '[data-todo-actions-row]', function (event) {
    $('.todo-alert').addClass('display-none');
    $('.todo-alert .message-body').html(null);
    $('.fixed-loader').removeClass('display-none');
  });

  $(document).on('ajax:error', '[data-todo-actions-row]', function (event, jqXHR) {
    errors = JSON.parse(jqXHR.responseText).errors;

    $.each(errors, function (idx, error) {
      var message = document.createElement('div');
      $(message).html(error);
      $('.todo-alert .message-body').append(message);
    })

    $('.todo-alert').removeClass('display-none');
    $('.fixed-loader').addClass('display-none');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".todo-alert").offset().top - 100
    }, 1000);
  });

  $(document).on('ajax:success', '[data-todo-actions-row]', function (event) {
    $.ajax({
      url: window.location.href,
      data: {
        format: 'js'
      }
    }).done(function () {
      $('.fixed-loader').addClass('display-none');
    })
  });
});
