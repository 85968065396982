import React from 'react';

export default class Fact extends React.Component {
  constructor(props) {
    super(props);
  }

  fact_icon(fact)
  {
    return(fact.icon ? fact.icon : 'angle-right')
  }

  render()
  {
    let fact = this.props.fact;

    if(!fact.value)
      return(null)
    else
      return(
        <div className="fact2">
          <div className="fact-icon">
            <i className={"fa fa-" + this.fact_icon(fact)} />
          </div>
          <div className="fact-info">
            <div className="name">{fact.name}</div>
            <div className="value">{this.simpleFormat(fact.value)}</div>
            {this.renderFactoids(fact.factoids)}
            {fact.actions}
          </div>
        </div>
      )
  }

  renderFactoids(factoids)
  {
    factoids = factoids || [];
    return(
      factoids.map( (factoid) => {
        <div className="factoid" key={factoid}>{factoid}</div>
      })
    )
  }

  simpleFormat(string)
  {
    return(
      string.split('\n').filter(x=>x).map((item, key) => {
        return <div className="line fact-line" key={key}>{item}</div>
      }))
  }
}
