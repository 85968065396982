$.hotkey = function(key, callback, args) 
{
  if(!args) args=[]; // IE barks when args is null
  $(document).keydown(function(e) 
  {
    if((e.shiftKey || e.altKey) && e.ctrlKey && e.which == key.charCodeAt(0)) 
    {
      e.preventDefault();
      callback.apply(this, args);
      return false;
    }
  });
};

