app.directive('rectangleHover', ['$window', function($window) {
  return {
    restrict: "AE",
    replace: true,
    link: function(scope, element, attrs) {
      var rects = element[0].querySelectorAll('rect');

      for (var i = 0; i < rects.length; i++) {
        rects[i].addEventListener('mouseover', function(e) {
          scope.tooltipVisible = true;
          scope.tooltipData = buildContents(this.dataset);
          scope.tooltipStyle = buildStyleBlock(this, e);
          scope.$apply();
        });

        rects[i].addEventListener('mouseout', function() {
          scope.tooltipVisible = false;
          scope.$apply();
        });
      }

      function buildContents(dataset) {
        return {
          serviceOrderId: dataset.serviceOrderId,
          customer: dataset.customer,
          eta: dataset.eta,
          etd: dataset.etd
        };
      }

      function buildStyleBlock(rect, e) {
        var clientRect = rect.getBoundingClientRect();
        var topConstant = 14;
        var sideConstant = -22;

        return {
          top: (clientRect.top + $window.scrollY + topConstant),
          left: (clientRect.left + $window.scrollX + sideConstant)
        };
      }
    }
  };
}]);
