(function($) {
  $.fn.businessHours = function(opts) {
    var container = $(this);

    var defaults = {
      preInit: function() {},
      postInit: function() {},
      inputDisabled: false,
      checkedColorClass: "WorkingDayState",
      uncheckedColorClass: "RestDayState",
      colorBoxValContainerClass: "colorBoxContainer",
      daysInWeek: 7,
      firstDayOfTheWeek: 0,
      weekdays: {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat'
      },
      operationTime: [0, 1, 2, 3, 4],
      startTimes: ['10:00'],
      defaultOperationTimeFrom: '9:00',
      defaultOperationTimeTill: '18:00',
      defaultActive: true,
      containerTmpl: '<div class="clean"/>',
      dayTmpl: '<div class="dayContainer" style="width: 80px;">' +
        '<div data-original-title="" class="colorBox"><input type="checkbox" class="invisible operationState"></div>' +
        '<div class="weekday"></div>' +
        '<div class="operationDayTimeContainer">' +
        '<div class="operationTime input-group"><span class="input-group-addon"><i class="fa fa-sun-o"></i></span><input type="text" name="startTime" class="mini-time form-control operationTimeFrom" value=""></div>' +
        '<div class="operationTime input-group"><span class="input-group-addon"><i class="fa fa-moon-o"></i></span><input type="text" name="endTime" class="mini-time form-control operationTimeTill" value=""></div>' +
        '</div></div>'
    }

    var something = [];

    function initTimeBox(timeBoxSelector, time, isInputDisabled) {
      timeBoxSelector.val(time);

      if (isInputDisabled) {
        timeBoxSelector.prop('readonly', true);
      }
    }

    var methods = {
      getValueOrDefault: function(val, defaultVal) {
        return (jQuery.type(val) === "undefined" || val == null) ? defaultVal : val;
      },
      init: function(opts) {
        this.selectedDays = [];
        this.options = $.extend(defaults, opts);
        container.html("");

        if (typeof this.options.preInit === "function") {
          this.options.preInit();
        }

        this.initView(this.options);

        if (typeof this.options.postInit === "function") {
          this.options.postInit();
        }

        return {
          serialize: function() {
            var data = {};

            container.find(".operationState").each(function(num, item) {
              var isWorkingDay = $(item).prop("checked");
              var dayContainer = $(item).parents(".dayContainer");

              if (isWorkingDay) {
                data[$(item).attr('day-value')] = { startTime: dayContainer.find('input.timepicker')[0].value, maxTime: dayContainer.find('select')[0].value };
              }
            });

            return data;
          }
        };

      },
      operationDayNodes: function(options) {
        const firstDay = options.firstDayOfTheWeek,
              lastDay = firstDay + options.daysInWeek,
              sortedWeekdays = _.map(_.range(firstDay, lastDay),
                                     function(n) { return n % options.daysInWeek });
        $.each(sortedWeekdays, function(index, weekdayNumber) {
          const weekdayName = options.weekdays[weekdayNumber],
                operationDayNode = container.find(".dayContainer").eq(index);

          operationDayNode.attr('day-value', weekdayNumber);
          operationDayNode.find('input.timepicker').attr('day-value', weekdayNumber);
          operationDayNode.find('select').attr('day-value', weekdayNumber);
          operationDayNode.find('.operationState').attr('day-value', weekdayNumber);
          operationDayNode.find('.weekday').html(weekdayName);
        });
      },
      initView: function(options) {
        var stateClasses = [options.checkedColorClass, options.uncheckedColorClass];
        var subContainer = container.append($(options.containerTmpl));
        var $this = this;

        for (var i = 0; i < options.daysInWeek; i++) {
          subContainer.append(options.dayTmpl);
        }

        this.operationDayNodes(options);
        for (const day of options.operationTime) {
          var operationDayNode = container.find(`.dayContainer[day-value='${day}']`)

          var isWorkingDay = options.weekdays[day]

          operationDayNode.find('.operationState').prop('checked', isWorkingDay);
        }

        container.find(".operationState").change(function() {
          var checkbox = $(this);
          var boxClass = options.checkedColorClass;
          var timeControlDisabled = false;

          if (!checkbox.prop("checked")) {
            boxClass = options.uncheckedColorClass;
            timeControlDisabled = true;
          }

          checkbox.parents(".colorBox").removeClass(stateClasses.join(' ')).addClass(boxClass);
          checkbox.parents(".dayContainer").find(".time__input").toggle(!timeControlDisabled);
          checkbox.parents(".dayContainer").find("select").toggle(!timeControlDisabled);

        }).trigger("change");

        if (!options.inputDisabled) {
          container.find(".colorBox").on("click", function() {
            var checkbox = $(this).find(".operationState");
            checkbox.prop("checked", !checkbox.prop('checked')).trigger("change");
          });
        }
      }
    };

    return methods.init(opts);
  };
})(jQuery);
