$(document).on('cocoon:after-insert', function(e) {
  $('[data-select=2]').select2();
});

$(document).on('cocoon:after-remove', function(e) {
  var container = $(e.target);
  var form = container.closest('form');

  if (container.attr('id') === 'parts-reports-items') {
    var partsBoxUsed = $('.report-parts-box-used[value="true"]', form).is(':checked');

    if(partsBoxUsed && $('.nested-fields', container).length == 0) {
      $('.add_fields', container).click();
    }
  }
});
