app.factory('TechReportService', ['TechReport', '$q', function(TechReport, $q) {
  var instance = {};

  instance.use_tech_report = function(resource) {
    var tech_report;
    if (resource.tech_report) {
      tech_report = resource.tech_report;
    } else {
      tech_report = resource;
    }

    if (!tech_report) {
      tech_report = new TechReport({ appointment_id: instance.appointment_id });
    }

    instance.tech_report = tech_report;
    instance.current_scope.tech_report = tech_report;
  };

  instance.initialize_tech_report = function(appointment_id, scope) {
    instance.appointment_id = appointment_id;
    instance.current_scope = scope;

    if (instance.tech_report === undefined || instance.tech_report.reportable_id !== appointment_id) {
      var promise = TechReport.get({ appointment_id: appointment_id });
      promise.then(instance.use_tech_report);
      return promise;
    } else {
      var action = $q.defer();
      var promise = action.promise;

      instance.use_tech_report(instance.tech_report);
      action.resolve();
      return promise;
    }
  };

  instance.invalidate = function() {
    instance.tech_report = undefined;
  };

  instance.persist = function() {
    if (instance.tech_report.id) {
      instance.tech_report.update().then(instance.use_tech_report);
    } else {
      instance.tech_report.create().then(instance.use_tech_report);
    }
  };

  return instance;
}]);
