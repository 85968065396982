//Parent class for NewServiceOrderCtrl and EditServiceOrderCtrl
app.controller('AbstractServiceOrderCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Customer', 'Client', 'ServiceOrder', 'WorkTimeEstimate',
  function($scope, $routeParams, $location, fixRailsSerializer, Customer, Client, ServiceOrder, WorkTimeEstimate) {
    $scope.hide_cheatsheet = true;

    WorkTimeEstimate.query().then( function(resource) {$scope.work_time_estimates = resource});

    $scope.change_client = function()
    {
      $scope.service_order.custom_1_title = $scope.service_order.client.custom_1_title;
      $scope.service_order.custom_2_title = $scope.service_order.client.custom_2_title;
      $scope.service_order.custom_3_title = $scope.service_order.client.custom_3_title;
      $scope.service_order.custom_4_title = $scope.service_order.client.custom_4_title;
    }
    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
  }
]);
