import React from 'react';
import StatusComponent from './MultiPictureStatusComponent';
import InputComponent from './MultiPictureInputComponent';
import ReturnLink from './MultiPictureReturnLink';
import DisplayComponent from './MultiPictureDisplayComponent';

export default class MultiPictureComponent extends React.Component {
  render()
  {
    return(
      <div>
        <div className="page-header">
          <h1>Upload Pictures for S{this.props.service_order_id}</h1>
          <InputComponent appointment_id={this.props.appointment_id} processFile={this.props.processFile} />
          <ReturnLink service_order_id={this.props.service_order_id} picture_count={this.props.picture_count} uploaded_picture_count={this.props.uploaded_picture_count} />
        </div>
        <StatusComponent picture_count={this.props.picture_count} uploaded_picture_count={this.props.uploaded_picture_count} />
        <DisplayComponent pictures={this.props.pictures} picture_count={this.props.picture_count} uploaded_picture_count={this.props.uploaded_picture_count} />
      </div>
    );
  }
}
