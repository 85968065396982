app.controller('CallTopicsCtrl', [
  '$scope', '$http', '$routeParams', 'fixRailsSerializer', 'Call', 'Client', 'Customer', 'Part', 'PartGroup', 'Topic', 'WarrantyRegistration',
  function($scope, $http, $routeParams, fixRailsSerializer, Call, Client, Customer, Part, PartGroup, Topic, WarrantyRegistration) {
    window.c = Customer;

    Topic.query().then( function(resource) { $scope.topics = resource; });
    Part.query().then( function(resource) { $scope.parts = resource; });
    PartGroup.query().then( function(resource) { $scope.part_groups = resource; });

    $scope.call = new Call({concerns: [], part_order: {lines: []}});

    $scope.deleted_concerns = [];
    $scope.deleted_part_order_lines = [];

    $scope.states = ['client_selection', 'warranty_selection', 'warranty_review', 'call']

    $scope.socket_recall_options = ['Not Affected', 'Immediate Service', 'Eventual Service', 'Service Declined'];

    Client.query({wants_customer_service: true}).then(
      function(clients)
      {
        $scope.clients = clients;
      }
    );

    search_for_registrations = function()
    {
      if( $scope.call.client_id )
      {
        WarrantyRegistration.$get('/warranty_registrations/search', {client_id: $scope.call.client_id, customer_id: $scope.call.customer_id}).then(
        function( results ) {
          $scope.registrations = results;
          $scope.set_apparent_state();
        });
      }
      else
      {
        $scope.warranty_registration = undefined;
      }
    }

    $scope.set_registration = function(result)
    {
      $scope.warranty_registration = result;
      $scope.call.warranty_registration_id = result.id;
      $scope.set_apparent_state();
    }

    $scope.new_registration = function()
    {
      $scope.warranty_registration = new WarrantyRegistration();
      $scope.warranty_registration.client_id = $scope.call.client_id;
      $scope.warranty_registration.customer_id = $scope.call.customer_id;
      $scope.state = 'warranty_review';
    }

    $scope.reset_topic = function(){ $scope.topic_id = null; }

    $scope.$watch('call.client_id', function(current, previous) {
      search_for_registrations();
    });

    $scope.init_call = function(atts)
    {
      $scope.persisted = atts['persisted'];

      $scope.call.client_id = $scope.call.client_id || atts['client_id'];
      $scope.call.customer_id = $scope.call.customer_id || atts['customer_id'];
      $scope.call.warranty_registration_id = $scope.call.warranty_registration_id || atts['warranty_registration_id'];
      $scope.set_apparent_state();
    }

    $scope.$watch('selecting_topic', function(value)
    {
      if( value == true ) { $('.add-topic-modal').modal('show'); }
      else { $('.add-topic-modal').modal('hide'); }
    })

    $scope.$watch('selecting_part', function(value)
    {
      if( value == true ) { $('.add-part-modal').modal('show'); }
      else { $('.add-part-modal').modal('hide'); }
    })

    $scope.should_show_part = function( part )
    {
      return( (!$scope.selected_part_group) || $scope.selected_part_group.part_ids.indexOf( part.id ) > -1 );
    }

    $scope.save_warranty_registration = function()
    {
      $scope.warranty_registration && $scope.warranty_registration.save().then(
      function( wr )
      {
        $scope.call.warranty_registration_id = wr.id;
        $scope.set_apparent_state();
      });
    }

    $scope.init_concerns = function( list )
    {
      angular.forEach(list, function( concern )
      {
        concern.ask_for_other = !!concern.other;
        $scope.call.concerns.push(concern);
      });
    }

    $scope.init_part_order_lines = function( list )
    {
      angular.forEach(list, function( po_line )
      {
        $scope.call.part_order.lines.push(po_line);
      });
    }


    function has_children( topic )
    {
      var has_kids = false;
      angular.forEach($scope.topics, function(needle) {has_kids = has_kids || needle.parent_id == topic.id; })

      return( has_kids );
    }

    $scope.set_topic = function(topic) {
      if(has_children( topic ))
      {
        $scope.topic_id = topic.id;
        $scope.topic_has_children = true;
      }
      else
      {
        $scope.call.concerns.push( {topic_id: topic.id, description: topic.name, ask_for_other: topic.ask_for_other});
        $scope.selecting_topic = false;
        $scope.topic_id = null;
      }
    }

    $scope.set_part = function( part, price_each )
    {
      $scope.call.part_order.lines.push( {part_id: part.id, description: part.description, quantity: 1, price_each: price_each} );
      $scope.selecting_part = false;
    }

    $scope.remove_concern = function(concern)
    {
      i = $scope.call.concerns.indexOf( concern );
      if( i != -1 )
      {
        $scope.call.concerns.splice(i, 1);
      }

      if( concern.id )
      {
        $scope.deleted_concerns.push(concern);
      }
    }

    $scope.remove_part = function(po_line)
    {
      i = $scope.call.part_order.lines.indexOf( po_line );
      if( i != -1 )
      {
        $scope.call.part_order.lines.splice(i, 1);
      }

      if( po_line.id )
      {
        $scope.deleted_part_order_lines.push(po_line);
      }
    }

    $scope.set_resolved = function( concern )
    {
      if( concern.resolved )
      {
        concern.service_reqd = false;
        concern.parts_reqd = false;
      }
    }

    $scope.total = function()
    {
      var ttl = 0.0;
      angular.forEach( $scope.call.part_order.lines, function(line) { ttl = ttl + (line.price_each * line.quantity); });
      return(ttl);
    }

    $scope.am_i_visible = function( state )
    {
      current_index = $scope.states.indexOf($scope.state)
      index_of_asking_state = $scope.states.indexOf(state);
  //console.log(state + ": (" + index_of_asking_state + " is <= " + current_index + ")")
      if( $scope.persisted )
        return( index_of_asking_state == current_index );
      else
        return( index_of_asking_state <= current_index );
    }

    $scope.apparent_state = function()
    {
      if( $scope.call.warranty_registration_id !== undefined && $scope.call.warranty_registration_id !== null )
        return('call');
      else if( $scope.call.client_id )
        return('warranty_selection');
      else if( $scope.warranty_registration )
        return('warranty_review')
      else
        return('client_selection');
    }

    $scope.set_apparent_state = function()
    {
      $scope.state = $scope.apparent_state();
    }

    $scope.can_submit = function()
    {
      return($scope.call.concerns && $scope.call.concerns.length > 0);
    }

    $scope.set_apparent_state();
  }
]);
