import React from 'react';

export default class MultiPictureDisplayComponent extends React.Component
{
  renderPicture(picture)
  {
    return(
      <div className="picture">
        <div className="picture-container">
          <img src={picture} width="95%" />
        </div>
      </div>
    )
  }

  renderProgressBar()
  {
    return(
      <div className="progress">
        <div className="bar bar-success" role="progressbar" aria-valuenow={percentComplete} aria-valuemin="0" aria-valuemax="100" style={{width: percentComplete + "%"}} >
          {percentComplete}%
        </div>
      </div>
    )
  }

  render()
  {
    let show_progress = false;

    if( this.props.picture_count == 0 )
    {
      return( null )
    }
    else if( this.props.picture_count > 0 )
    {
      let percentComplete = ( 100 * this.props.uploaded_picture_count ) / this.props.picture_count;

      percentComplete = Math.round( percentComplete * 100 ) / 100;

      return(
        <div>
          {show_progress ? this.renderProgressBar() : null}
          {this.props.pictures.map( this.renderPicture )}
        </div>
      )
    }
  }
}
