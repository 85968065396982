import React from 'react';
import Address from '../common/Address';

export default (props) => {
  var id_str = "customer_" + props.customer.id;
  return(
    <div className="saw-Customer-Brief fact2" id={id_str}>
      <i className="fa fa-address-card fact-icon" />
      <div className="fact-info">
        <div className="customer-name value">{props.customer.first_name} {props.customer.last_name}</div>
        <Address location={props.customer.location} />
      </div>
    </div>
  )
}
