app.controller('BobsCtrl', [
  '$scope', 'SchedulerService', 'UnmanagedRoute',
  function($scope, SchedulerService, UnmanagedRoute) {
    function find_available_routes()
    {
      routes = [];

      if( $scope.assignment && $scope.assignment.activity_type == "UnmanagedRoute" )
      {
        $scope.available_unmanaged_routes = [];

        $.ajax(
        {
          url: '/unmanaged_routes/availability.json',
          dataType: 'json',
          async: false,  // this is why I needed $.ajax (probly)
          data:
          {
            date: $scope.assignment.date,
            technician_id: $scope.assignment.employee_id,
            team: $scope.assignment.team
          },
          success: function(response)
          {
            var available_unmanaged_routes = response.unmanaged_routes;
            if( $scope.assignment.description > "" )
            {
                available_unmanaged_routes.push( $scope.assignment.description )
            }

            $scope.available_unmanaged_routes = available_unmanaged_routes.sort();
          }
        });
      }
    }

    $scope.$watch('assignment', find_available_routes);

    $scope.save_bob = function( assignment )
    {
      if( assignment.activity_id === null )
      {
        var ur = new UnmanagedRoute(
        {
          name: assignment.description,
          date: assignment.date,
          technician_id: assignment.employee_id
        });
        ur.create().then(function(ur)
          {
            assignment.activity_id = ur.id;
            SchedulerService.add_assignment( assignment );
          });
      }
      else
      {
        var ur = new UnmanagedRoute({id: assignment.activity_id, name: assignment.description});
        ur.update();
      }

      SchedulerService.clear_assignment();
    }
  }
]);
