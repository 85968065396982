import * as SignaturePad from 'signature-pad-1.5.3.min';

$(document).ready(function () {
  if ($('body.customer_acknowledgements.new').length > 0) {
    $('#customer_acknowledgement_signature').val(
      document.getElementById('signature').toDataURL()
    );

    var canvas = document.getElementById("signature");
    var signaturePad = new SignaturePad(canvas);

    if($('#customer_acknowledgement_signature').val().length > 100)
    {
      signaturePad.fromDataURL($('#customer_acknowledgement_signature').val());
    }

    $('.btn-clear').click(function(){ signaturePad.clear(); });

    $('form').submit(function()
    {
      if(!signaturePad.isEmpty())
      {
        $('#customer_acknowledgement_signature').val(signaturePad.toDataURL());
      }
    });
  }
});
