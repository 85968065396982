SawSite = {
  showBusy: function() {
    $("#ajax_busy").fadeIn();
  },
  hideBusy: function() {
    $("#ajax_busy").fadeOut();
  },

  badDates: [],
  goodDates: [],
  dateMatch: function (a, b) {
    return (a.getDate() == b.getDate() &&
            a.getMonth() == b.getMonth() &&
            a.getFullYear() == b.getFullYear());
  },

  directionsDisplay: null,
  directionsService: null,
  sortable: function (sel,opts) {
    var rc = $(sel)
    rc.sortable(opts);
    SawSite.portletify(sel);
    rc.disableSelection();
  },

  portletify: function(sel) {
    $(sel + " .portlet").addClass("ui-widget ui-widget-content ui-helper-clearfix ui-corner-all")
        .find(".portlet-header")
            .addClass("ui-widget-header ui-corner-all")
            .prepend('<span class="ui-icon ui-icon-plusthick"></span>')
            .end()
        .find(".portlet-content");

    $(sel + " .portlet-header .ui-icon").click(function() {
        $(this).toggleClass("ui-icon-minusthick");
        $(this).parents(".portlet:first").find(".portlet-content").toggle();
    });

  },

  call: {
      current_id: null,
      tabs: null,
      rebind_cust: function() {
        $("#customer_errors").ajaxError(function(e, xhr, settings, exc) {
          if (xhr.status == 422 && settings.url == '/customers.json'){
            var ce = $(this);
            ce.empty();
            $("#error_template").jqote($.parseJSON(xhr.responseText)).appendTo(ce);
            SawSite.hideBusy();
            ce.fadeIn();
          }

        });
        $("#customer_form_area form").submit(function(evt) {
            evt.preventDefault();
            var custid = $("#customer_id");
            if (custid.val().length != 0) {
              SawSite.call.tabs.tabs('select', 1);
            } else {
              SawSite.showBusy();
              $.post("/customers.json", $(this).serializeArray(), function(data) {
                if (data.customer !== undefined) {
                  custid.val( data.customer.id );
                  SawSite.call.tabs.tabs('select', 1);
                } else {
                }
                SawSite.hideBusy();
              });
            }
          });
      },
      cancel_call: function(reason) {
        SawSite.showBusy();
        $.post('/calls/' + SawSite.call.current_id + '/cancel.json', { reason: reason }, function() {
          $("#cancel_status").html("The call has been cancelled.").show();
          SawSite.hideBusy();
          window.location.href = '/calls/' + SawSite.call.current_id;
        });
      },
      confirm_post: function(reason, redirect_loc) {
        SawSite.showBusy();
        $.post('/calls/' + SawSite.call.current_id + '/confirm.json', { confirmed_person: reason }, function() {
          $("#confirm_status").html("The call has been confirmed.").show();
          SawSite.hideBusy();
          window.location.href = redirect_loc;
        });
      },
      add_call_to_route: function(reason) {
        SawSite.showBusy();
        $.post('/api/routes/add_call.json', {
          call_id: SawSite.call.current_id,
          route_id: SawSite.call.route_id,
          reason: reason
          }, function(data) {
            window.location.href = '/routes/' + SawSite.call.route_id;
            SawSite.hideBusy();
        });
      },
      bind_call_ctrls: function() {
        $("#cancel_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          width: 600,
          buttons: {
             'Do Not Cancel': function() {
               $(this).dialog('close');
             },
            'Return to Vendor': function() {
               $(this).dialog('close');
               SawSite.call.cancel_call('Call Returned to Vendor');
            },
            'No Contact': function() {
               $(this).dialog('close');
               SawSite.call.cancel_call('No Contact with Customer');
            },
            'No Service Needed': function() {
               $(this).dialog('close');
               SawSite.call.cancel_call('No Service Needed');
            }
          }
        });

        $("#route_add_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          width: 650,
          buttons: {
             'Do Not Schedule': function() {
               $(this).dialog('close');
             },
             'Mr': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Mr');
             },
             'Mrs': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Mrs');
             },
             'Son': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Son');
             },
             'Daughter': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Daughter');
             },
             'Store': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Store');
             },
             'Admin': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Admin');
             },
             'Message Left': function() {
               $(this).dialog('close');
                SawSite.call.add_call_to_route('Message Left');
             }
          }
        });

        $("#reschedule_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/calls/' + SawSite.call.current_id + '/reschedule.json', function() {
                  $("#reschedule_status").html("The call has been rescheduled.").show();
                  SawSite.hideBusy();
                  window.location.href = '/calls/' + SawSite.call.current_id;
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $("#complete_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/calls/' + SawSite.call.current_id + '/complete.json', function() {
                  $("#complete_status").html("The call has been completed.").show();
                  SawSite.hideBusy();
                  window.location.href = '/calls/' + SawSite.call.current_id;
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $("#confirm_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
             'Mr': function() {
                SawSite.call.confirm_post("Mr", '/calls/' + SawSite.call.current_id);
             },
             'Mrs': function() {
                SawSite.call.confirm_post("Mrs", '/calls/' + SawSite.call.current_id);
             },
             'Son': function() {
                SawSite.call.confirm_post("Son", '/calls/' + SawSite.call.current_id);
             },
             'Daughter': function() {
                SawSite.call.confirm_post("Daughter", '/calls/' + SawSite.call.current_id);
             },
             'Message Left': function() {
                SawSite.call.confirm_post("Message Left", '/calls/' + SawSite.call.current_id);
             },
             'Store': function() {
                SawSite.call.confirm_post("Store", '/calls/' + SawSite.call.current_id);
             },
             'Admin': function() {
                SawSite.call.confirm_post("Admin", '/calls/' + SawSite.call.current_id);
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });


        $("#follow_up_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               window.location.href = '/calls/new?parent_call_id=' + SawSite.call.current_id;
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $(".call_controls #follow_up").click(function(evt) {
          $("#follow_up_dialog").dialog('open');
        });
        $(".call_controls #cancel").click(function(evt) {
          $("#cancel_dialog").dialog('open');
        });
        $(".call_controls #schedule_call").click(function(evt) {
          $("#sched_dialog").dialog('open');
        });
        $(".call_controls #reschedule_call").click(function(evt) {
          $("#reschedule_dialog").dialog('open');
        });
        $(".call_controls #confirm_call").click(function(evt) {
          $("#confirm_dialog").dialog('open');
        });
        $(".call_controls #complete_call").click(function(evt) {
          $("#complete_dialog").dialog('open');
        });
      }
  }, // call
  routes: {
      call_id: null,
      route_id: null,
      tech_location: null,
      bind_route_list: function() {
        var route_id = $(".route_title").attr("id").replace("route_","");
        SawSite.routes.bind_remove_links();
        SawSite.sortable("#route_calls", {
          connectWith: '#undated_route_calls',
          update: function() {
            var calls = $("#route_calls").sortable('serialize');
            SawSite.showBusy();
            $.post("/api/routes/update_order.json?" + calls, { route_id: route_id }, SawSite.routes.update_assigned, 'json');
          },
          remove: function() {
            alert("call removed");
          }
        });
      },

      bind_route_ctrls: function() {
        $("#hide_undated_calls").click(function() {
            $("#undated_calls").hide();
            clear_undated_calls();
        });

        $("#cancel_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/routes/' + SawSite.routes.route_id + '/cancel.json', function() {
                  $("#cancel_status").html("The route has been cancelled.").show();
                  window.location.href = '/routes/' + SawSite.routes.route_id;
                  SawSite.hideBusy();
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $("#close_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/routes/' + SawSite.routes.route_id + '/close.json', function() {
                  $("#close_status").html("The route has been closed.").show();
                  window.location.href = '/routes/' + SawSite.routes.route_id;
                  SawSite.hideBusy();
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $("#complete_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/routes/' + SawSite.routes.route_id + '/complete.json', function() {
                  $("#complete_status").html("The route has been completed.").show();
                  window.location.href = '/routes/' + SawSite.routes.route_id;
                  SawSite.hideBusy();
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $("#reopen_dialog").dialog({
          bgiframe: true, modal: true, autoOpen: false,
          overlay: {
            backgroundColor: '#000',
            opacity: 0.5
          },
          buttons: {
            'Yes': function() {
               $(this).dialog('close');
               SawSite.showBusy();
               $.post('/routes/' + SawSite.routes.route_id + '/reopen.json', function() {
                  $("#reopen_status").html("The route has been reopened.").show();
                  window.location.href = '/routes/' + SawSite.routes.route_id;
                  SawSite.hideBusy();
               });
             },
             No: function() {
               $(this).dialog('close');
             }
          }
        });

        $(".route_main_controls #cancel").click(function(evt) {
          $("#cancel_dialog").dialog('open');
        });
        $(".route_main_controls #close").click(function(evt) {
          $("#close_dialog").dialog('open');
        });
        $(".route_main_controls #complete").click(function(evt) {
          $("#complete_dialog").dialog('open');
        });
        $(".route_main_controls #reopen").click(function(evt) {
          $("#reopen_dialog").dialog('open');
        });

      },

      bind_remove_links: function() {
        $("a[rel=remove_call]").click(function(evt) {
          evt.preventDefault();
          $("#remove_call_dialog").dialog('open');
          SawSite.routes.remove_call_url = $(this).attr("href");
        });
      },

      update_assigned: function(data) {
        window.location.href = window.location.href;
      },
      add_call: function(reason) {
        SawSite.showBusy();
        $.post('/api/routes/add_call.json', {
          call_id: SawSite.routes.call_id,
          route_id: SawSite.routes.route_id,
          reason: reason
          }, function(data) {
            window.location.href = '/routes/' + SawSite.routes.route_id;
            SawSite.hideBusy();
          });
      },

      refresh_undated: function() {
        $.getJSON("/api/calls/undated.json", { route_id: SawSite.routes.route_id },
            function(data) {
          var uncall = $("#undated_calls");
          var lst = $("#undated_route_calls");
          lst.empty();
          if (data == null || data.length == 0) {
            lst.html("No undated calls");
            uncall.slideDown("normal");
            return;
          }
          var tpl = $("#call_template");
          tpl.jqote(data).appendTo(lst);
          SawSite.portletify("#undated_route_calls");
          $("#undated_route_calls div").click(function(evt) {
            SawSite.routes.call_id = $(this).attr("id").replace("call_","");
            $("#undated_route_calls").slideUp("normal");
            $("#add_call_dialog").dialog('open');
          });

          uncall.slideDown("normal");
        });
      }
  }, // routes
  customers: {
      dupeCheck: function(evt) {
          var ctl = $(this);
          var phone = $("#customer_primary_phone").val();
          var email = $("#customer_email").val();
          $.post('/api/customers/dupe_check', { phone: phone, email: email }, function(data) {
              if (data == null || data.length == 0) { return; }
              var lst = $("<div />")
              var tpl = $("#customer_dupe_template");
              tpl.jqote(data).appendTo(lst);
              SawSite.customers.alternates = data;
              ctl.swift('Possible duplicate of', {
                  basis: 'div',
                  html_body: "<br/><div class='custlist'>" + lst.html() + "</div>",
                  buttons: {
                    cancel: {
                      label: 'Cancel',
                      action: function(){}
                    }
                  }
                });
              $(".alternate_customer").click(function(evt) {
                $.post("/api/customers/refresh_form", {
                      customer_id: $(evt.currentTarget).attr("id").replace("cust_","")
                    }, function(data) {
                      $('#customer_form_area').html(data);
                      $.swift_close();
                      if (SawSite.call.tabs != null) {
                        SawSite.call.rebind_cust();
                        $("#call_customer_id").val($("#customer_id").val());
                      }
                    });
              });
            },
            'json');
      }
  } // customers

}; // SawSite

$(function() {

  $.datepicker._defaults.dateFormat = 'mm/dd/yy';

  $(".previous_date_picker").datepicker({
    showOn: 'both',
    buttonImage: '/images/icons/calendar_16x16.png',
    buttonImageOnly: true,
    changeYear: true,
    changeMonth: true
  });

  $(".date_on_click").datepicker({
    showOn: 'button',
    buttonImage: '/images/icons/calendar_16x16.png',
    buttonImageOnly: true,
    changeYear: true,
    changeMonth: true
  });

  $(".date_picker").datepicker(
    {
      numberOfMonths: 2,
      showOn: 'both',
      buttonImage: '/images/icons/calendar_16x16.png',
      buttonImageOnly: true,
      beforeShowDay: function(date) {
        goodDates = SawSite.goodDates;
        badDates = SawSite.badDates;
        retval = true;
        if (goodDates.length > 0) {
          $.each(goodDates, function (i, val) {
            if (retval && SawSite.dateMatch(val, date)) {
              retval = false;
            }
          });
        }
        if (badDates.length > 0) {
          $.each(badDates, function (i, val) {
            if (retval && SawSite.dateMatch(val, date)) {
              retval = false;
            }
          });
        }

        return [ retval ];
      },
      onSelect: function (date, inst) {
        $.getJSON('/api/users/find_available_on.json?date=' + date,
            function(data) {
              var x = $("#tpl_tech_opt").jqote(data);
              var tech = $("#route_technician_id");
              tech.find("option[value!='']").remove();
              x.appendTo(tech);
              $(".technician_box").slideDown();
            }
        );
      }

    });

  var flashes = $("#flash");
  if (flashes.length > 0) {
    $(document).mousemove(function() {
      $("#flash div").fadeOut("slow");
      $(document).unbind('mousemove');
    });
  }

  flashes.click(function(){
    $("#flash div").fadeOut("slow");
  });

  $("#add_call_report_scan").click(function(evt) {
      evt.preventDefault();
      $(".report_scan_add").slideDown("normal");
  });

  $("#add_note").click(function(evt) {
      evt.preventDefault();
      $(".note_add").slideDown("normal");
      $("#call_text_summary").val("");
      $("#call_text_body").val("");
  });

  $("#hide_add_note").click(function(evt) {
      evt.preventDefault();
      $(".note_add").slideUp("normal");
  });

  $("#hide_add_call_report_scan").click(function(evt) {
      evt.preventDefault();
      $(".report_scan_add").slideUp("normal");
  });

  $("#add_image_form").submit(function(evt) {
      evt.preventDefault();
      var frm = $(evt.currentTarget);
      SawSite.showBusy();
      frm.ajaxSubmit({
                      iframe: true,
                      success: function(data,st) {
            $(data).prependTo($("#image_list"));
            $(".image_add").slideUp("normal");
            var ic = $("#image_count");
            var oi = /\((\d+)\)/.exec( ic.html() );
            ic.html("Images (" + (parseInt(oi[1]) + 1) + ")");
            SawSite.hideBusy();
      }});
  });

  $("#add_report_scan_form").submit(function(evt) {
      evt.preventDefault();
      var frm = $(evt.currentTarget);
      SawSite.showBusy();
      frm.ajaxSubmit({
                      iframe: true,
                      success: function(data,st) {
            $(data).prependTo($("#report_scan_list"));
            $(".image_add").slideUp("normal");
            var ic = $("#report_scan_count");
            var oi = /\((\d+)\)/.exec( ic.html() );
            ic.html("Report Scans (" + (parseInt(oi[1]) + 1) + ")");
            SawSite.hideBusy();
      }});
  });

  $("#add_note_form").submit(function(evt) {
      var frm = $(evt.currentTarget);
      SawSite.showBusy();
      $.post(frm.attr("action"), frm.serialize(), function(data,st) {
        var list = $("#note_list");
        var tpl = $("#note_template");
        tpl.jqote(data).prependTo(list);
        $("#note_count").html("Notes (" + data[0].note_count + ")");
        $(".note_add").slideUp("normal");
        SawSite.hideBusy();
      }, "json");
      evt.preventDefault();
  });


  $("#new_user").submit(function(evt) {
    evt.preventDefault();
    var frm = $(evt.currentTarget);
    $("#role_list .user_role").each(function(i, role) {
      $("<input />", { type: "hidden", name: "roles[]", value: $(role).attr("id") }).appendTo(frm);
    });
    evt.currentTarget.submit();
  });

  $(".edit_user").submit(function(evt) {
    evt.preventDefault();
    var frm = $(evt.currentTarget);
    $("#role_list .user_role").each(function(i, role) {
      $("<input />", { type: "hidden", name: "roles[]", value: $(role).attr("id") }).appendTo(frm);
    });
    evt.currentTarget.submit();
  });


  $("#add_call").click(function(evt) {
    evt.preventDefault();
    SawSite.routes.refresh_undated();
  });

  $("#q").focus(function(evt) {
      var q = $(this);
      if (q.val() == 'search') { q.val(''); }
  });

  $("#q").blur(function(evt) {
      var q = $(this);
      if (q.val() == '') { q.val('search'); }
  });

  $("#customer_primary_phone").change(SawSite.customers.dupeCheck);
  $("#customer_email").change(SawSite.customers.dupeCheck);
  SawSite.call.tabs = $('#call_wizard').tabs();

  $("button").button();
  $("input[type=submit]").not('.plain').button();
});


function extracted_from_zone_form(){
  SawSite.zipcode_update = function() {
    SawSite.showBusy();
    $.getJSON('/api/zipcodes.json', { 'city': $('#city').val(), 'state': $("#state").val() }, function(data) {
      var lst = $("#available_zipcodes");

      lst.empty();
      var bad_ids = [];
      var existing = $("#assigned_zipcodes .available_zipcodes");
      existing.each(function(i,zc) {
        bad_ids += zc.attr("id");
      });
      var clean_data = [];
      $.each(data,function(i,r) {
        if ($.inArray(r.id, bad_ids) == -1) {
          clean_data.push( r );
        }
      });

      var zips = $("#zip_template").jqote(clean_data);

      $.each(zips, function(idx, val) {
        val.appendTo(lst);
      });


      lst.find(".assigned_zipcode").click(function(evt) {
        evt.preventDefault();
        var targ = $(evt.currentTarget);
        if (targ.parents("#zipcodes").length == 0) {
          targ.appendTo( $("#zipcodes") );
        }
        else {
          targ.remove();
        }

      });

      SawSite.hideBusy();
    });
  };
  $("#zipcodes .assigned_zipcode").click(function(evt) {
    evt.preventDefault();
    var targ = $(evt.currentTarget);
    targ.remove();
  });
  $("#main form").submit(function(evt) {
    evt.preventDefault();
    var frm = $("#main form");
    $("#zipcodes .assigned_zipcode").each(function(i, zc) {
      $("<input />", { type: "hidden", name: "zipcodes[]", value: $.trim($(zc).html()) }).appendTo(frm);
    });
    evt.currentTarget.submit();
  });
  $("#city").change(SawSite.zipcode_update);
  $("#state").change(SawSite.zipcode_update);
}

function fullcalendar_for_call_schedule(){
  $("#calendar").fullCalendar({
    theme: true,
    events: '/api/routes/for_calendar.json?call_id=' + SawSite.routes.call_id,
    eventClick: function(event, jsEvent, view) {
      $(this).swift('Add call to this route?', {
        buttons: {
          yes: { label: 'Yes', action: function() {
            route_id = event.id.replace("route_","");
            $.post('/api/routes/add_call.json', {
                call_id: SawSite.routes.call_id,
                route_id: route_id
              }, function(data) {
              window.location.href = '/routes/' + route_id;
            });
          } },
          cancel: { label: 'Cancel', action: function() {} }
        }
     });
    },
    dayClick: function(date, allDay, jsEvent, view) {
      $(this).swift('Schedule new route for this day?', {
         buttons: {
           yes: { label: 'Yes', action: function() {
             window.location.href = '/routes/new?call_id=' + SawSite.routes.call_id + '&date=' + date;
           } },
           cancel: { label: 'Cancel', action: function() {} }
         }
      });
    }
  });
}
