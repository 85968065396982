let customerSaveThunk = (data) => {
}

let customerSearchThunk = (criteria) => {
  return function(dispatch, getState)
  {
    let fn = criteria.first_name || "";
    let ln = criteria.last_name;
    let zipcode = criteria.zipcode;

    if(ln && zipcode && zipcode.length >= 5 && ln.length > 1)
    {
      let success = (response) => {
        dispatch({type: 'CUSTOMER_SEARCH_POPULATE', customers: response.customers});
        dispatch({ type: 'REQUEST_SUCCESS' });
        dispatch({ type: 'ALLOW_NEW_CUSTOMER' });
      }

      let failure = (response) => { dispatch({type: 'NETWORK_REQUEST_FAILED', response: response}) }

      dispatch({
        type: 'REQUEST_PENDING'
      });

      $.get('/customers.json',
        {customer_search: {first_name: fn, last_name: ln, zipcode: zipcode}}).then(success, failure);
    }
    else
    {
      dispatch({type: 'CUSTOMER_SEARCH_RESET'})
    }
  }
}

let customerLoadThunk = (id) => {
  return function(dispatch, getState)
  {
    if( id )
    {
      let success = (response) => { dispatch({type: 'CUSTOMER_POPULATE', customer: response.customer}); dispatch({ type: 'REQUEST_SUCCESS' }); }

      let failure = (response) => { dispatch({type: 'NETWORK_REQUEST_FAILED', response: response}) }

      dispatch({
        type: 'REQUEST_PENDING'
      });

      $.get('/customers/' + id + '.json').then(success, failure);
    }
  }
}

let customerNotesLoadThunk = (id) => {
  return function(dispatch, getState)
  {
    if( id )
    {
      let success = (response) => { dispatch({type: 'CUSTOMER_NOTES_POPULATE', notes: response}); dispatch({ type: 'REQUEST_SUCCESS' }); }

      let failure = (response) => { dispatch({type: 'NETWORK_REQUEST_FAILED', response: response}) }

      dispatch({
        type: 'REQUEST_PENDING'
      });

      $.get('/customers/' + id + '/notes.json').then(success, failure);
    }
  }
}

let servicesLoadThunk = (id) => {
  return function(dispatch, getState)
  {
    if( id )
    {
      let success = (response) => { dispatch({type: 'SERVICES_POPULATE', services: response.history}); dispatch({ type: 'REQUEST_SUCCESS' }); }

      let failure = (response) => { dispatch({type: 'NETWORK_REQUEST_FAILED', response: response}) }

      dispatch({
        type: 'REQUEST_PENDING'
      });

      $.get('/customers/' + id + '/history.json').then(success, failure);
    }
  }
}

export {customerSearchThunk, customerLoadThunk, servicesLoadThunk, customerSaveThunk, customerNotesLoadThunk};
