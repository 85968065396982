import React from 'react';
import TextInput from '../common/TextInput';
import CustomerBrief from './Brief';
import LocationForm from '../Locations/Form';
import PhoneNumbersForm from '../Customers/PhoneNumbersForm';
import LocationDisambiguator from '../Locations/Disambiguator';
import Map from '../Locations/Map';
import Address from '../common/Address';
import {Link} from 'react-router-dom'
import {potentiallyGeocode} from '../../tools/mapping'


let LoadingIndicator = (props) => {
  if(props.isLoading)
  {
    return(<h2>Loading...</h2>);
  }
  else
  {
    return(null);
  }
}

export default class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.saveCustomer = this.saveCustomer.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.saveMappingAddress = this.saveMappingAddress.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleNestedAddressChanges = this.handleNestedAddressChanges.bind(this);
    this.handleNestedMappingAddressChanges = this.handleNestedMappingAddressChanges.bind(this);

    this.handleDisambiguation = this.handleDisambiguation.bind(this);
    this.handleMappingAddressDisambiguation = this.handleMappingAddressDisambiguation.bind(this);

    this.isUntrackable = this.isUntrackable.bind(this);
    this.checkIfLocationIsTrackable = this.checkIfLocationIsTrackable.bind(this);

    this.handleTypedLocationChange = this.handleTypedLocationChange.bind(this);
    this.handleTypedMappingAddressChange = this.handleTypedMappingAddressChange.bind(this);

    this.updateGeolocationFeedback = this.updateGeolocationFeedback.bind(this);

    this.allowSaving = this.allowSaving.bind(this);
    this.removeMappingAddress = this.removeMappingAddress.bind(this);

    this.addPhoneNumber = this.addPhoneNumber.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);

    this.handleMultipleMatches = this.handleMultipleMatches.bind(this);
    this.handleMultipleMappingAddressMatches = this.handleMultipleMappingAddressMatches.bind(this);

    this.closeFeature = this.closeFeature.bind(this);
    this.openFeature = this.openFeature.bind(this);
    this.openAddressModal = this.openAddressModal.bind(this);
    this.openMappingAddressModal = this.openMappingAddressModal.bind(this);

    let searchCriteria = props.location && props.location.state || {};
    this.state = Object.assign({}, props.customer);

    let mappingAddressId = this.state.mapping_address.id;
    let mappingAddressLocationId = this.state.mapping_address.location.id;

    this.state.mapping_address = Object.assign({}, this.state.mapping_address.location);
    this.state.mapping_address.id = mappingAddressId;
    this.state.mapping_address.location_id = mappingAddressLocationId;

    this.state.location.zipcode = (this.state.location && this.state.location.zipcode) || searchCriteria.zipcode;
    this.state.first_name = this.state.first_name || searchCriteria.first_name;
    this.state.last_name = this.state.last_name || searchCriteria.last_name;

    this.state.tmpLocation = Object.assign({}, this.state.location);
    this.state.tmpMappingAddress = Object.assign({}, this.state.mapping_address.location);

    this.state.saving = false;
    this.state.addressLoading = false;
    this.state.mappingAddressLoading = false;

    this.state.locations = [];
    this.state.mappingAddresses = [];
    this.state.mappingAddressGeolocationError = null;
    this.state.addressGeolocationError = null;
    this.state.errors = {};

    this.state.isTrackingLocation = false;
    this.state.isTrackingMappingAddress = false;
  }

  componentDidMount()
  {
    if(this.props.location && this.props.location.state)
    {
      let input = this.props.location.state;
      if(input)
      {
        this.handleTypedLocationChange('zipcode', input.zipcode);
      }
    }

    if(this.props.mapping_address && this.props.mapping_address.state)
    {
      let input = this.props.mapping_address.state;
      if(input)
      {
        this.handleTypedMappingAddressChange('zipcode', input.zipcode);
      }
    }

    this.addPhoneNumber();
  }

  handleChange(name, value, callback)
  {
    let change = {};
    change[name] = value;
    this.setState(change, callback)
  }

  updateGeolocationFeedback(error) {
    alert(error);
  }

  handleNestedAddressChanges(parentKey, callback)
  {
    return( (changeset) => {
      let changed = Object.assign({}, this.state[parentKey], changeset);
      let error = null;

      if (changeset && changeset.geocoderResponse && changeset.geocoderResponse.error) {
        error = changeset.geocoderResponse.error;
      }

      this.setState({ addressGeolocationError: error }, () => {
        this.handleChange(parentKey, changed, callback);
      })
    });
  }

  handleNestedMappingAddressChanges(parentKey, callback)
  {
    return( (changeset) => {
      let changed = Object.assign({}, this.state[parentKey], changeset);
      let error = null;

      if (changeset && changeset.geocoderResponse && changeset.geocoderResponse.error) {
        error = changeset.geocoderResponse.error;
      }

      this.setState({ mappingAddressGeolocationError: error }, () => {
        this.handleChange(parentKey, changed, callback);
      })
    });
  }

  handleDisambiguation(location)
  {
    let self = this
    this.setState({ isTrackingLocation: true });
    this.checkIfLocationIsTrackable(location).then(function (untrackable) {
      var temp = Object.assign({}, location);
      temp.cross_street = '';

      if (untrackable) {
        temp.status = 'untrackable';
      }

      self.setState({ tmpLocation: temp, locations: [], addressGeolocationError: null }, function () {
        self.setState({ isTrackingLocation: false });
      });
    })
  }

  handleMappingAddressDisambiguation(location)
  {
    let self = this
    this.setState({ isTrackingMappingAddress: true });
    this.checkIfLocationIsTrackable(location).then(function (untrackable) {
      var temp = Object.assign({}, location);
      temp.cross_street = '';

      if (untrackable) {
        temp.status = 'untrackable';
      }
      self.setState({ tmpMappingAddress: temp, mappingAddresses: [], mappingAddressGeolocationError: null }, function () {
        self.setState({ isTrackingMappingAddress: false });
      });
    })
  }

  handleMultipleMatches(locations)
  {
    this.setState({ locations: locations })
  }

  handleMultipleMappingAddressMatches(locations)
  {
    this.setState({ mappingAddresses: locations })
  }

  addPhoneNumber()
  {
    this.setState({ phone_numbers: this.state.phone_numbers.concat({}) })
  }

  handlePhoneNumberChange(replacement, callback)
  {
    this.handleChange('phone_numbers', replacement, callback);
  }

  handleTypedLocationChange(name, value)
  {
    this.setState({ addressLoading: true }, () => {
      let callback = () => {
        potentiallyGeocode({
          change: name,
          onChanges: (changes) => {
            this.handleNestedAddressChanges('tmpLocation', () => {
              this.setState({ addressLoading: false })
            })(changes)
          },
          onMultipleMatches: this.handleMultipleMatches,
          location: this.state.tmpLocation,
          skip: () => { this.setState({ addressLoading: false }) }
        });
      }

      let changeset = {};
      changeset[name] = value;
      this.handleNestedAddressChanges('tmpLocation', callback)(changeset);
    });
  }

  handleTypedMappingAddressChange(name, value)
  {
    this.setState({ mappingAddressLoading: true }, () => {
      let callback = () => {
        potentiallyGeocode({
          change: name,
          onChanges: this.handleNestedMappingAddressChanges('tmpMappingAddress', () => {
            this.setState({ mappingAddressLoading: false })
          }),
          onMultipleMatches: this.handleMultipleMappingAddressMatches,
          location: this.state.tmpMappingAddress,
          skip: () => { this.setState({ mappingAddressLoading: false }) }
        });
      }

      let changeset = {};
      changeset[name] = value;
      this.handleNestedMappingAddressChanges('tmpMappingAddress', callback)(changeset);
    })
  }

  closeFeature()
  {
    this.setState({
      feature: null,
      mappingAddressGeolocationError: null,
      addressGeolocationError: null,
      mappingAddresses: [],
      locations: []
    })
  }

  openFeature(desc)
  {
    this.setState({ feature: desc })
  }

  renderError(error, objectName)
  {
    if(error && Object.keys(error).length !== 0)
    {
      return(<div className="alert alert-danger">{objectName} {error}</div>)
    }
    else
    {
      return(null)
    }
  }

  renderCompleteMappingAddress(location, error)
  {
    if(location)
    {
      return(
        <div className="value">
          <Address location={location} onChange={this.handleNestedMappingAddressChanges('mapping_address')} />
        </div>
      )
    }
    else
    {
      return( <div className="value">Waiting for valid address</div> )
    }
  }

  renderCompleteAddress(location, error)
  {
    if(location)
    {
      return(
        <div className="value">
          <Address location={location} onChange={this.handleNestedAddressChanges('location')} showCrossStreet="true" />
        </div>
      )
    }
    else
    {
      return( <div className="value">Waiting for valid address</div> )
    }
  }

  allowSaving()
  {
    return(this.concernsFor(this.state).length == 0 && !this.state.saving)
  }

  saveCustomer()
  {
    this.setState({saving: true});
    this.setState({errors: []});

    let success = (response) => {
      let customer = response.customer;

      if(customer.id)
      {
        this.props.history.push('/customers/' + customer.id);
      }
    }
    let failure = (response) => {
      this.setState({saving: false})

      if( response.status == 422 ) {
        var res = JSON.parse(response.responseText);
        this.setState({errors: res['errors']})
      }
    }

    let data = {
      mapping_address_allowed: true,
      ignore_geocoder: true,
      customer:
      {
        id: this.state.id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        location_attributes: {
          id: this.state.location.id,
          street1: this.state.location.street1,
          street2: this.state.location.street2,
          city: this.state.location.city,
          state: this.state.location.state,
          zipcode: this.state.location.zipcode,
          latitude: this.state.location.latitude,
          longitude: this.state.location.longitude,
          cross_street: this.state.location.cross_street,
          status: this.state.location.status
        },
        mapping_address_attributes: {
          id: this.state.mapping_address.id,
          location_attributes: {
            id: this.state.mapping_address.location_id,
            street1: this.state.mapping_address.street1,
            street2: this.state.mapping_address.street2,
            city: this.state.mapping_address.city,
            state: this.state.mapping_address.state,
            zipcode: this.state.mapping_address.zipcode,
            latitude: this.state.mapping_address.latitude,
            longitude: this.state.mapping_address.longitude,
            cross_street: this.state.mapping_address.cross_street
          }
        },
        phone_numbers: this.state.phone_numbers,
      },
    };

    if( this.state.id )
    {
      $.ajax({type: 'PUT', url: '/customers/' + this.state.id + '.json', data: data, success: success, error: failure});
    }
    else
    {
      $.ajax({type: 'POST', url: '/customers.json', data: data, success: success, error: failure});
    }
  }

  concernsFor(customer)
  {
    let out = [];
    let pn = null;

    /*
    if(! (customer.location && Number.isInteger(customer.location.id) && !customer.location.longitude ))
    {
      out.push("Resolve ambiguous address (We only allow ambiguous addresses when customers are created. On edit, it MUST be fixed.)")
    }
    */

    if(!(customer.location && customer.location.street1 && customer.location.street1.length > 0))
    {
      out.push("Street address required")
    }

    if(! (customer.phone_numbers && (pn = customer.phone_numbers[0]) &&
       pn.description && pn.description.length > 0 && pn.number &&
       pn.number.length > 0))
    {
      out.push("Phone Number with Description")
    }

    if(!(customer.first_name && customer.first_name.length > 0))
    {
      out.push("First Name")
    }

    if(!(customer.last_name && customer.last_name.length > 0))
    {
      out.push("Last Name")
    }

    return(out);
  }

  renderCompletionHint(customer)
  {
    let concerns = this.concernsFor(customer);

    if(concerns.length == 0)
    {
      return(null);
    }
    else
    {
      return(
        <div className="alert alert-info fancy-alert alert-tall">
          <div className="alert-awesome">
            <i className="fa fa-exclamation-triangle"></i>
          </div>

          <div className="alert-content">
            Requirements to save this customer:
            <ul>
              {concerns.map((item, i)=> <li key={i}>{item}</li>)}
            </ul>
          </div>
        </div>
      )
    }
  }

  renderAddressHint(location)
  {
    if( !location || location.street1 == undefined || location.street1.length == 0 )
    {
       return(
         <div className="alert alert-info">Please click on the button to enter the customer address</div>
       )
    }
    else if( !location.id && location.street1.length > 0 && !location.latitude && !this.hasMappingLocation())
    {
       return(
         <div className="alert alert-error fancy-alert alert-tall">
           <div className="alert-awesome">
             <i className="fa fa-flag"></i>
           </div>
           <div className="alert-content">
             <h4>Ambiguous Address</h4>
             <p>The address you entered is not a known address. Please confirm with your customer. If they live in a new development, it may not appear on our maps yet. Please ask for an address nearby and add that to our notes.</p>
             <p><b>Unless corrected, this may cause a delay in scheduling.</b></p>
           </div>
         </div>
       )
    }
    else if (this.isUntrackable(location) && !this.hasMappingLocation()) {
      return(
       <div className="alert alert-error fancy-alert alert-tall">
         <div className="alert-awesome">
           <i className="fa fa-exclamation-triangle"></i>
         </div>
         <div className="alert-content">
           <h4>Untrackable Address</h4>
           <p>This customer's address cannot be tracklable on our map provider. This may be a military or protected location.</p>
           <p>Please ask a new location for your customer or add a Mapping Address.</p>
           <p><b>Untrackable addresses may cause a delay in scheduling.</b></p>
         </div>
       </div>
     )
    }
    else if( location.id && location.street1.length > 0 && !location.latitude && !this.hasMappingLocation() )
    {
       return(
         <div className="alert alert-error fancy-alert alert-tall">
           <div className="alert-awesome">
             <i className="fa fa-exclamation-triangle"></i>
           </div>
           <div className="alert-content">
             <h4>Ambiguous Address</h4>
             <p>This customer's address does not appear to be a real address, according to our map provider. We allow 'ambiguous' addresses to be entered initially; but nothing else can be done with this customer record until the address is corrected. Please confirm with your customer. If they live in a new development, it may not appear on our maps yet. Please ask for an address nearby and add that to our notes.</p>
             <p><b>Ambiguous addresses may cause a delay in scheduling.</b></p>
           </div>
         </div>
       )
    } else {
      return(null);
    }
  }

  hasMappingLocation()
  {
    return this.state.mapping_address && this.state.mapping_address && this.state.mapping_address.street1;
  }

  removeMappingAddress()
  {
    this.setState({ tmpMappingAddress: {}}, () => {
      this.setState({ mapping_address: {}})
    })
  }

  renderRemoveMappingAddressButton()
  {
    if (this.hasMappingLocation()) {
      return(<button type="button" className="btn btn-danger" onClick={this.removeMappingAddress} tabIndex={6} >Remove</button>)
    }
  }

  renderMappingAddress()
  {

    let errors = {};

    if(this.state.errors.mapping_address) {
      errors = this.state.errors.mapping_address;
    }

    return(
      <div className="control-group">
        <label className="control-label">Mapping Address</label>
        <div className="controls">
          {this.renderCompleteAddress(this.state.mapping_address, 'mapping_address', errors)}

          {this.renderError(errors, null)}
          {this.renderError(this.state.errors['mapping_address.street1'], 'street1')}
          {this.renderError(this.state.errors['mapping_address.coordinates'], 'location')}

          <button type="button" className="btn btn-default" onClick={this.openMappingAddressModal} tabIndex={5} >Edit Mapping Address</button>
          {this.renderRemoveMappingAddressButton()}
        </div>
      </div>
    )
  }

  openAddressModal() {
    this.setState({ tmpLocation: this.state.location }, () => {
      this.openFeature('location');
    });
  }

  openMappingAddressModal() {
    this.setState({ tmpMappingAddress: this.state.mapping_address }, () =>{
      this.openFeature('mapping_address');
    });
  }

  render()
  {
    return(
      <div>
        <div className="page-header"><h2>Customer {this.state.first_name} {this.state.last_name}</h2></div>

        <div className="row">
          <div className="span5 col-sm-5">
            <form className="form form-horizontal" id="new_customer_form">
              <TextInput label="First Name" name="first_name" required={true} autocomplete="off" value={this.state.first_name} onChange={this.handleChange} tabindex={1} focus={true} error={this.state.errors.first_name} required={true} />
              <TextInput label="Last Name" name="last_name" required={true} autocomplete="off" value={this.state.last_name} onChange={this.handleChange} tabindex={2} error={this.state.errors.last_name} required={true}/>
              <TextInput label="Email" name="email" required={false} type="email" autocomplete="off" value={this.state.email} onChange={this.handleChange} hint="Enter CUSTOMER email address." tabindex={3} error={this.state.errors.email}  />

              <div className="control-group">
                <label className="control-label">Address</label>
                <div className="controls">
                  {this.renderCompleteAddress(this.state.location, 'location', this.state.errors.location)}

                  {this.renderError(this.state.errors.location, null)}
                  {this.renderError(this.state.errors['location.street1'], 'street1')}
                  {this.renderError(this.state.errors['location.coordinates'], 'location')}

                  <button type="button" className="btn btn-default" onClick={this.openAddressModal} tabIndex={4} >Edit Location</button>
                </div>
              </div>

              {this.renderMappingAddress()}
            </form>
          </div>
          <div className="span7 col-sm-7">
            <PhoneNumbersForm phone_numbers={this.state.phone_numbers} onChange={this.handlePhoneNumberChange} addPhoneNumber={this.addPhoneNumber} error={this.state.errors.phone_numbers} />

            {this.renderError(this.state.errors.phone_numbers, null)}
            {this.renderError(this.state.errors['phone_numbers.number'], 'Number')}

            {this.renderAddressHint(this.state.location)}
            {this.renderCompletionHint(this.state)}
            <button onClick={this.saveCustomer} className="btn btn-primary" disabled={!this.allowSaving()} className="btn btn-primary">Save Customer</button>
          </div>
        </div>
        { this.renderLocationModal(this.state.feature == 'location') }
        { this.renderMappingAddressModal(this.state.feature == 'mapping_address') }
      </div>
    )
  }

  saveAddress()
  {
    this.setState({ location: this.state.tmpLocation }, () => {
      this.closeFeature();
    });
  }

  saveMappingAddress()
  {
    let changes = this.state.tmpMappingAddress;

    if (this.state.mapping_address.zipcode !== this.state.tmpMappingAddress.zipcode ||
        this.state.mapping_address.street1 !== this.state.tmpMappingAddress.street1 ||
        this.state.mapping_address.street2 !== this.state.tmpMappingAddress.street2 ||
        this.state.mapping_address.city !== this.state.tmpMappingAddress.city ||
        this.state.mapping_address.state !== this.state.tmpMappingAddress.state ||
        this.state.mapping_address.cross_street !== this.state.tmpMappingAddress.cross_street) {

      changes.id = null;
      changes.location_id = null;
    }

    this.setState({ mapping_address: changes}, () => {
      this.closeFeature();
    })
  }

  renderLocationModal(shouldRender=false)
  {
    if(shouldRender)
    {
      return(
        <div className="modal-center-container">
          <div className="modal-center">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.closeFeature} aria-hidden="true">&times;</button>
              <h3>Customer Location</h3>
            </div>
            <div className="modal-body">
              {this.renderUntrackableAddressAlert()}

              <div className="address-row">
                <div className="address-col-30">
                  {this.renderAddressGeolocationFeedback('address-small-alert')}
                  <LocationForm location={this.state.tmpLocation} onChange={this.handleTypedLocationChange} tabindex={4} />
                </div>
                <div className="address-col-70">
                  {this.renderAddressGeolocationFeedback('address-big-alert')}
                  {this.renderAddressResponses()}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {this.renderSaveAddressButton()}
            </div>
          </div>
          <div onClick={this.closeFeature} className="modal-backdrop fade in"></div>
        </div>
      )
    }
    else
      return null
  }

  renderMappingAddressModal(shouldRender=false)
  {
    if(shouldRender)
    {
      return(
        <div className="modal-center-container">
          <div className="modal-center">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.closeFeature} aria-hidden="true">&times;</button>
              <h3>Customer Mapping Address</h3>
            </div>
            <div className="modal-body">
              <div className="alert alert-error fancy-alert">
                <div className="alert-content">
                  The Mapping Address should be a valid and map trackable location. Please, fill up it correctly!
                </div>
              </div>

              {this.renderUntrackableMappingAddressAlert()}

              <div className="address-row">
                <div className="address-col-30">
                  {this.renderMappingAddressGeolocationFeedback('address-small-alert')}
                  <LocationForm location={this.state.tmpMappingAddress} onChange={this.handleTypedMappingAddressChange} tabindex={4} />
                </div>
                <div className="address-col-70">
                  {this.renderMappingAddressGeolocationFeedback('address-big-alert')}
                  {this.renderMappingAddressResponses()}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {this.renderSaveMappingAddressButton()}
            </div>
          </div>
          <div onClick={this.closeFeature} className="modal-backdrop fade in"></div>
        </div>
      )
    }
    else
      return null
  }

  renderSaveAddressButton()
  {
    let canSave = !this.state.addressLoading && !this.state.saving && !this.state.isTrackingLocation &&
                  this.state.tmpLocation.state && this.state.tmpLocation.city &&
                  !this.state.addressGeolocationError;

    return(<button type='button' onClick={this.saveAddress} disabled={!canSave} className="btn btn-primary">Save</button>)
  }

  renderSaveMappingAddressButton()
  {
    let canSave = !this.state.mappingAddressLoading && !this.state.saving && !this.state.isTrackingMappingLocation &&
                  this.state.tmpMappingAddress.state && this.state.tmpMappingAddress.city &&
                  this.state.tmpMappingAddress.latitude && !this.state.mappingAddressGeolocationError &&
                  !this.isUntrackable(this.state.tmpMappingAddress);

    return(<button type='button' onClick={this.saveMappingAddress} disabled={!canSave} className="btn btn-primary">Save</button>)
  }

  renderAddressGeolocationFeedback(additionalClass)
  {
    if (this.state.addressGeolocationError) {
      return(<div className={ "alert alert-danger " + additionalClass }>{this.state.addressGeolocationError}</div>)
    }
  }

  renderMappingAddressGeolocationFeedback(additionalClass)
  {
    if (this.state.mappingAddressGeolocationError) {
      return(<div className={"alert alert-danger " + additionalClass }>{this.state.mappingAddressGeolocationError}</div>)
    }
  }

  renderAddressResponses()
  {
    if (this.state.addressLoading) {
      <div className="alert alert-info">
        <p>Loading...</p>
      </div>
    }

    if (this.state.addressGeolocationError && this.state.locations.length === 0) {
      return
    }

    if (this.state.isTrackingLocation) {
      return([
        <div className="alert alert-info">
          <p>Verifying if the address is trackable...</p>
        </div>
      ])
    }

    return([
      <LocationDisambiguator locations={this.state.locations} onChange={this.handleDisambiguation} />,
      <div className="width-full mb-20">
        <Map mapID="locationMap" location={this.state.tmpLocation} />
      </div>
    ])
  }

  renderMappingAddressResponses()
  {
    if (this.state.mappingAddressLoading) {
      return(<p>Loading...</p>)
    }

    if (this.state.mappingAddressGeolocationError && this.state.mappingAddresses.length === 0) {
      return
    }

    return([
      <LocationDisambiguator locations={this.state.mappingAddresses} onChange={this.handleMappingAddressDisambiguation} />,
      <div className="width-full mb-20">
        <Map mapID="mappingAddressMap" location={this.state.tmpMappingAddress} />
      </div>
    ])
  }

  renderUntrackableAddressAlert()
  {
    if (this.state.tmpLocation && this.state.tmpLocation.latitude && this.isUntrackable(this.state.tmpLocation)) {
      return (
        <div className="alert alert-error">
          <p><strong>Untrackable Address</strong></p>
          <p>This location cannot be tracklable on our map provider. This may be a military or protected location. Note that it is possible to keep it as Untrackable and add a valid <b>Mapping Address</b></p>
        </div>
      )
    }
  }

  renderUntrackableMappingAddressAlert()
  {
    if (this.state.tmpMappingAddress && this.state.tmpMappingAddress.latitude && this.isUntrackable(this.state.tmpMappingAddress)) {
      return (
        <div className="alert alert-error">
          <p><strong>Untrackable Address</strong></p>
          <p>This location cannot be tracklable on our map provider. This may be a military or protected location. Mapping Address must be a valid Trackable Location</p>
        </div>
      )
    }
  }

  checkIfLocationIsTrackable(location) {
    return $.get('/locations/check_trackbility', { location: location }).then((response) => {
      return !response.trackable;
    })
  }

  isUntrackable(location) {
    return location.status == 'untrackable'
  }
}
