app.controller('SubReportCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Appointment', 'Customer', 'Client', 'Furniture', 'RepairReport', 'ServiceOrder', 'TechReport', 'Calendar', 'TechReportService', 'IssueCauses', 'IssueUsages',
  function SubReportCtrl($scope, $routeParams, $location, fixRailsSerializer, Appointment, Customer, Client, Furniture, RepairReport, ServiceOrder, TechReport, Calendar, TechReportService, IssueCauses, IssueUsages) {
    $scope.report = $routeParams['sub_report'];
    $scope.appointment_id = $routeParams['appointment_id'];
    $scope.furniture_report_id = $routeParams['furniture_report_id'];

    TechReportService.initialize_tech_report( $scope.appointment_id, $scope ).then(function()
    {
      angular.forEach($scope.tech_report.furniture_reports, function(furniture_report)
      {
        if( furniture_report.id == $routeParams['furniture_report_id'] )
        {
          $scope.furniture_report = furniture_report;
        }
      });
    });

    $scope.persist_report = function( data, resource )
    {
      go_back = function() { $location.path('/appointments/' + $scope.appointment_id + '/tech_report') };

      switch( $scope.report )
      {
        case 'repair_report':
          report = new RepairReport( data );
          break;
        case 'mattress_report':
          report = new MattressReport(data);
          break;
      }

      if( report.id )
      {
        report.update().then(
            function( data )
            {
              TechReportService.invalidate();
              go_back();
            });
      }
      else
      {
        TechReportService.invalidate();
        report.furniture_report_id = $scope.furniture_report_id;
        report.create().then(go_back);
      }
    }

    $scope.repair_outcomes = JSON.stringify(["Complete", "Customer Refused", "Not Covered by Warranty", "No Service Needed", "Wrong Parts Sent", "Parts Required", "Exchange", "Need More Time", "Inspection Complete"]);
    $scope.cleaning_outcomes = JSON.stringify(["Successful", "Unsuccessful", "Cleaning Not Performed", "Not Covered", "Inspection Complete"]);
    $scope.mattress_sizes = JSON.stringify(["Twin", "Double", "Full", "Queen", "King", "CA King"]);
    $scope.floor_types = JSON.stringify(["Rug", "Wood"]);
    $scope.support_systems = JSON.stringify(["", "Metal Frame", "Slats", "Center Rail", "Platform", "No Frame"]);
    $scope.frame_types = JSON.stringify(["Metal", "Wood", "No Frame"]);
    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];
    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];

  }
]);
