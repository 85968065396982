app.controller('GridCtrl', [
  '$scope', '$filter', '$routeParams', '$location', 'fixRailsSerializer', 'Calendar', 'Activity', 'Assignment', 'AssignmentGrid', 'Employee', 'Day', 'AssignmentNote', 'SchedulerService', 'Superzone',
  function($scope, $filter, $routeParams, $location, fixRailsSerializer, Calendar, Activity, Assignment, AssignmentGrid, Employee, Day, AssignmentNote, SchedulerService, Superzone) {
    function target_start_date()
    {
      if( $routeParams['week_of'] !== undefined )
      {
        return( Calendar.stringToDate( $routeParams['week_of'] ));
      }
      else
      {
        return( Calendar.monday() );
      }
    }

    function target_team()
    {
      return( $routeParams['team'] );
    }

    function target_superzone()
    {
      if( $scope.superzones !== undefined )
      {
        return( $filter('getById')($scope.superzones, $routeParams['superzone_id']) );
      }
    }

    $scope.this_week = Calendar.monday();
    $scope.week_of = target_start_date();
    $scope.team = target_team();

    Activity.query({ active: true, show_on_assignment_grid: true }).then(function(resource)
    {
      $scope.activities = resource.activities;
      $('table.sticky').stickyTableHeaders({fixedOffset: $('.navbar-fixed-top')});
    });

    $scope.teams = ['Technicians', 'Operations', 'Ashley', 'Bobs', 'Jeromes'];

    Superzone.query({status: 'In Service'}).then(function(superzones)
    {
      all_list = [new Superzone({name: 'All', id: 0})];
      $scope.superzones = all_list.concat(superzones);
      $scope.superzone = target_superzone();
    });

    $scope.grid = {};

    can_process = function()
    {
      if( $scope.week_of !== undefined && $scope.team !== undefined && ( $scope.team == 'Operations' || $scope.team == 'Ashley' || $scope.team == 'Bobs' || $scope.team == 'Jeromes' || !!$scope.superzone ) )
      {
        return true;
      }
      else
      {
        return false;
      }
    };

    $scope.update_parameters = function()
    {
      if( can_process() )
      {
        $scope.grid = {};

        params =
        {
           week_of: Calendar.dateToString( $scope.week_of ),
           team: $scope.team
        }

        if( $scope.superzone )
        {
          params['superzone_id'] = $scope.superzone.id;
        }

        $location.search( params );

        initialize();
      }
    }

    function initialize()
    {
      $scope.grid = new AssignmentGrid({week_of: $scope.week_of, team: $scope.team, superzone: $scope.superzone });
      $scope.grid.load_employees();
    };

    $scope.$watch('week_of', $scope.update_parameters);
    $scope.$watch('team', $scope.update_parameters);
    $scope.$watch('superzone', $scope.update_parameters);

    $scope.edit = SchedulerService.edit_schedule
    $scope.describe = SchedulerService.describe;
  }
]);
