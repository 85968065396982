import PropTypes from 'prop-types';
import React from 'react';
import CustomerSearch from '../containers/Customers/CustomerSearchContainer';
import NewCustomer from '../containers/Customers/NewCustomerContainer';
import EditCustomer from '../containers/Customers/EditCustomerContainer';
import Customer from '../containers/Customers/CustomerContainer';

import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom'
// https://stackoverflow.com/questions/44958758/how-to-prevent-matching-two-routes-with-react-router-v4#answer-44971945

export default class ServiceParentComponent extends React.Component {
  render() {
    return (
      <Router basename="/react">
        <Switch>
          <Route path="/" exact={true} component={WelcomeScreen} />
          <Route path="/customers/search" exact={true} component={CustomerSearch} />
          <Route path="/customers/new" exact={true} component={NewCustomer} />
          <Route path="/customers/:customer_id" exact={true} component={Customer} />
          <Route path="/customers/:customer_id/edit" exact={true} component={EditCustomer} />
        </Switch>
      </Router>
    );
  }
}

const WelcomeScreen = (props) => {
  return(
  <div>
    <h3>Services App</h3>
    <Link to={`/customers/search`}>Customer Search</Link>
    <Link to={`/routes/manage`}>Route Manager</Link>
  </div>
  );
}
