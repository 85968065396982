app.controller('WorkspaceCtrl', [
  '$scope', '$filter', '$location', 'fixRailsSerializer', 'Calendar', 'Activity', 'Assignment', 'AssignmentGrid', 'Employee', 'Day', 'AssignmentNote', 'UnmanagedRoute', 'SchedulerService',
  function($scope, $filter, $location, fixRailsSerializer, Calendar, Activity, Assignment, AssignmentGrid, Employee, Day, AssignmentNote, UnmanagedRoute, SchedulerService) {
    $scope.show_add_unmanaged_route_link = function(schedule, assignment)
    {
      return( !SchedulerService.schedule_has_unmanaged_route() );
    };

    $scope.clear_editor = SchedulerService.clear;

    $scope.route_scheduled = SchedulerService.schedule_has_route;

    $scope.visit_new_route_form = function()
    {
      date=$filter('date')($scope.schedule.date, 'yyyy-MM-dd');
      technician_id=$scope.schedule.employee_id;

      document.location = "/ng/#/routes/new?date=" + date + "&technician_id=" + technician_id;
    }

    $scope.save_assignment = function(assignment)
    {
      assignment.activity_type = 'Activity';

      if( assignment.id === undefined )
      {
        assignment.create().then( function(response) {
          response.activity = undefined;
          SchedulerService.add_assignment( response );
          SchedulerService.clear_assignment();
        });
      }
      else
      {
        assignment.update().then(function(response) {
          response.activity = undefined;
          SchedulerService.clear_assignment();
        });
      }
    }

    $scope.delete_assignment = SchedulerService.delete_assignment
    $scope.edit_assignment = SchedulerService.edit_assignment;
    $scope.initialize_assignment = SchedulerService.new_assignment;
    $scope.new_unmanaged_route = SchedulerService.new_unmanaged_route;

    function update_schedule()
    {
      $scope.schedule = SchedulerService.schedule();
    }

    function update_assignment()
    {
      $scope.assignment = SchedulerService.assignment();
    }

    $scope.save_note = SchedulerService.save_note;
    $scope.scheduler = SchedulerService;
    $scope.$watch('scheduler.schedule()', update_schedule);
    $scope.$watch('scheduler.assignment()', update_assignment);
  }
]);
