//google.load('visualization', '1', {packages:['corechart']});

app.controller('ClientAnalyticsCtrl', [
  '$scope', '$route', '$routeParams', '$location', '$q', 'Client', 'Calendar',
  function($scope, $route, $routeParams, $location, $q, Client, Calendar) {
    $scope.todays_date = Calendar.dateToString( new Date() );

    Client.get($routeParams['client_id']).then(
      function( resource )
      {
        $scope.client = resource;
      }
    );

    $.get('/clients/' + $routeParams['client_id'] + '/analytics.json').then(
      function(resource)
      {
        $scope.report = resource;
      }
    );

    function make_datatable(datum_name, column_two_label)
    {
      var table = [];
      table.push( ['Week Of', column_two_label] );
      var datum = $scope.datum_named( datum_name );
      table = table.concat(datum.history)
      return(google.visualization.arrayToDataTable(table));
    }

    $scope.datum_named = function(datum_name)
    {
      if( $scope.report )
      {
        return(_.find( $scope.report.data, function( datum ){ return(datum['name'] == datum_name) }));
      }
    }

    function sparkline_options()
    {
      return(
        {
          hAxis: { textPosition: 'none', baselineColor: 'none', ticks: [] },
          vAxis: { textPosition: 'none', baselineColor: 'none', ticks: [] },
          legend: {position: 'none'},
          width: 150,
          height: 40
        }
      )
    }

    function plot_datum(datum_name, html_id, field)
    {
      if( $scope.report )
      {
        var element = document.getElementById(html_id);
        var chart = new google.visualization.LineChart( element )
        chart.draw( make_datatable(datum_name, field), sparkline_options() );
      }
    }

    function plot_completion_breakdown_chart()
    {
      if( $scope.report )
      {
        var complete = $scope.datum_named('Completed Appointment Count');
        var incomplete = $scope.datum_named('Incomplete Appointment Count');
        var done = $scope.datum_named('Done Appointment Count');

        var table = [['Status', 'Appointments'], ['Complete', complete.value],['Incomplete', incomplete.value],['Done', done.value]];
        table = google.visualization.arrayToDataTable(table);

        var element = document.getElementById('completion_breakdown_chart');
        var chart = new google.visualization.PieChart( element )
        chart.draw( table, {width: 150, height: 150, legend: {position: 'none'}} );

      }
    }

    $scope.$watch('report', function(){
         plot_datum('Services Performed', 'services_performed_chart', 'Appointments')
         plot_datum('Average Cost Per Service', 'average_cost_chart', 'Cost')
         plot_datum('Completion Rate', 'completion_rate_chart', 'Completion Rate')
         plot_completion_breakdown_chart();
    });
  }
]);
