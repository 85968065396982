app.controller('VariancesCtrl', [
  '$scope', '$http', '$routeParams', 'fixRailsSerializer', 'Variance', 'Route', 'Calendar',
  function($scope, $http, $routeParams, fixRailsSerializer, Variance, Route, Calendar) {

    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    $scope.$watch('selected_variance.route_id', function(current, previous) {
      if( $scope.route )
      {
        $scope.mapHelper.remove($scope.route.outline);
      }

      if( current )
      {
        $scope.filter_start = $scope.selected_variance.window.start;
        $scope.filter_end = $scope.selected_variance.window.finish;

        Route.get( current ).then(function(route)
        {
          $scope.route = route;
          $scope.mapHelper.outlineRoute(route, true, {});
        });
      }
    });

    function filter_start( )
    {
      if( $scope.apply_time_filter && $scope.route && $scope.filter_start )
      {
        route_date = Calendar.stringToDate($scope.route.date);
        var time = Calendar.stringToTime($scope.filter_start, route_date);

        return(Calendar.dateToString( time, 'ymdhms'));
      }
      else
      {
        return(false);
      }
    }

    function filter_end( )
    {
      if( $scope.apply_time_filter && $scope.route && $scope.filter_end )
      {
        route_date = Calendar.stringToDate($scope.route.date);
        var time = Calendar.stringToTime($scope.filter_end, route_date);

        return(Calendar.dateToString( time, 'ymdhms'));
      }
      else
      {
        return(false);
      }
    }

    function add_variance_by_id( id )
    {
      Variance.get(id).then(
        function(response)
        {
          $scope.selected_variance = response;
        }
      );
    }

    $scope.update_variance_list = function()
    {
      add_variances_above_id( maximum_variance_id() );

      // var refresh_interval = 30 * 1000;
      // setTimeout( $scope.update_variance_list, refresh_interval );
    }

    function initialize()
    {
      $scope.apply_time_filter = true;
      $scope.variance_id = $routeParams['variance_id'];

      $http.get('/variance_reasons.json').then(
        function( response )
        {
          $scope.variance_reasons = response.data;
        }
      )

      if( $scope.variance_id )
      {
        add_variance_by_id( $scope.variance_id );
      }
      else
      {
        $scope.variances = [];
        $scope.update_variance_list();
      }
    }

    function variance_ids()
    {
      out = [];

      angular.forEach( $scope.variances, function( variance )
      {
        out.push( variance.id );
      });

      return( out );
    }

    function add_variances_above_id( id )
    {
      Variance.query({minimum_id: id}).then(
        function(response)
        {
          angular.extend( $scope.variances, response );
        }
      );
    }

    function maximum_variance_id()
    {
      var out = Math.max.apply(null, variance_ids());

      if( out == -Infinity )
      {
        return(0);
      }
      else
      {
        return( out );
      }
    }

    $scope.select = function( variance )
    {
      $scope.selected_variance = variance;

      if( $scope.variance_reasons.indexOf( $scope.selected_variance.reason ) > -1 )
      {
        $scope.selected_reason = $scope.selected_variance.reason;
      }
      else
      {
        $scope.selected_reason = 'Other';
        $scope.other_reason = $scope.selected_variance.reason;
      }
    }

    $scope.save_selected_variance = function()
    {
      if( $scope.selected_reason == 'Other' )
      {
        $scope.selected_variance.reason = $scope.other_reason;
      }
      else
      {
        $scope.selected_variance.reason = $scope.selected_reason;
      }

      $scope.selected_variance.update();
      var index = $scope.variances.indexOf($scope.selected_index);
      $scope.variances.splice(index, 1);
      $scope.selected_variance = null;
    }

    initialize();
  }
]);
