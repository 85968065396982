app.controller('RouteSharedCtrl', [
  '$scope', 'Route',
  function($scope, Route) {
    $scope.shuffle_route = function(datum)
    {
      $('#api-access').show();

      $.ajax({
        url:'/appointments/shuffle.json',
        type: 'PUT',
        data: {
          shuffle: datum
        },
        success: function( resource )
        {
          $('#api-access').hide();
          angular.forEach(resource.routes, $scope.use_route);
          $scope.$apply();
        },
        error: function( resource )
        {
          $('#api-access').hide();
          $('#api-error').show();
        }
      });
    };

    $scope.shuffle_data = function( routes )
    {
      var output = {};

      angular.forEach( routes, function(route)
      {
        angular.forEach( route.waypoints, function(waypoint, position )
        {
          angular.forEach( waypoint.appointments, function( appt )
          {
            output[appt.id] = {route_id: route.id, position: position};
          });
        });
      });

      return output;
    };
  }
]);
