app.controller('ServiceOrderListsCtrl', [
  '$scope', '$http', 'fixRailsSerializer', 'ServiceOrder',
  function($scope, $http, fixRailsSerializer, ServiceOrder) {

    $scope.undated = ServiceOrder.query({undated: true});
    $scope.scheduled = ServiceOrder.query({scheduled: true});


    // $http.get('/dashboards/service_orders_by_client').success(function(data){
    //   $scope.service_orders_by_client = data['dashboards'];
    // });
    // $scope.records_present = function(){
    //   return($scope.service_orders_by_client !== undefined && $scope.service_orders_by_client.length > 0);
    // };
  }
]);
