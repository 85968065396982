
$.hotkey('C', call_dialog);
$.hotkey('S', service_order_dialog);
$.hotkey('R', route_dialog);
$.hotkey(' ', search);

function call_dialog()
{
  $('#call_dialog').modal('show');
}

function service_order_dialog()
{
  $('#service_order_dialog').submit(function(){
    document.location = "/service_orders/" + $('#service_order_dialog #service_order_id').val();
    return false;
  });
  $('#service_order_dialog').modal('show');

  // modal('show') takes a bit to get started... if we focus too soon,
  // the input isn't visible and can't focus.
  setTimeout(function(){
    $('#service_order_dialog #service_order_id').focus();
  }, 400);
}

function route_dialog()
{
  $('#route_dialog').submit(function(){
    document.location = "/routes/" + $('#route_dialog #route_id').val();
    return false;
  });
  $('#route_dialog').modal('show');

  // modal('show') takes a bit to get started... if we focus too soon,
  // the input isn't visible and can't focus.
  setTimeout(function(){
    $('#route_dialog #route_id').focus();
  }, 400);
}

function search() {
  $('#search-dialog').submit(function(){ document.location = "/search/?" + $('#search-dialog form').serialize(); return false;});
  
  $('#search-dialog').modal('show');
  $('#search-dialog #search-term').focus();
}
