import {connect} from 'react-redux';
import Customer from '../../components/Customers/Show';
import {customerLoadThunk, servicesLoadThunk, customerNotesLoadThunk} from '../../thunks/customerThunk';

let customer2facts = (customer) => {
  if(customer)
    return(address_facts(customer).concat(mapping_address_facts(customer)).concat(phone_facts(customer)).concat(email_facts(customer)));
  else
    return([]);
}

let phone_facts = (customer) => {
  if (!customer){
    return [];
  }

  return(
    customer.phone_numbers.map((number) => {
      return {
        icon: number.allow_sms ? 'phone' : 'phone-square',
        name: number.description + ' number',
        value: number.number
      }
    })
  )
}

let address_facts = (customer) => {
  if (!customer || !customer.location){
    return [];
  }

  let address =
    [customer.location.street1,
     customer.location.street2,
     customer.location.city + ", " + customer.location.state,
     customer.location.cross_street,
     customer.location.zipcode].filter((x) => x).join("\n");

  return [{
    icon: 'map-marker',
    name: 'Address',
    value: address,
    ambiguous: customer.location.ambiguous,
    status: customer.location.status
  }]
}

let mapping_address_facts = (customer) => {
  if (!customer || !customer.mapping_address || !customer.mapping_address.location || !customer.mapping_address.location.street1){
    return [];
  }

  let address =
    [customer.mapping_address.location.street1,
     customer.mapping_address.location.street2,
     customer.mapping_address.location.city + ", " + customer.mapping_address.location.state,
     customer.mapping_address.location.cross_street,
     customer.mapping_address.location.zipcode].filter((x) => x).join("\n");

  return [{
    id: customer.mapping_address.id,
    icon: 'map-marker',
    name: 'Mapping Address',
    value: address,
    ambiguous: customer.mapping_address.location.ambiguous,
    status: customer.location.status
  }]
}

let email_facts = (customer) => {
  if (!customer){
    return [];
  }

  return [{
    icon: 'map-marker',
    name: 'Email',
    value: customer.email
  }]
}

let mapStateToProps = (state, ownprops) => {
  let customer = ownprops.customer || state.entities.customer;
  let services = state.entities.services;

  return(
  {
    'customer': customer,
    'feature_flags': state.feature_flags,
    'facts': {
      'address': address_facts(customer),
      'mapping_address': mapping_address_facts(customer),
      'email': email_facts(customer),
      'phone': phone_facts(customer)
    },
    'services': services,
    'notes': state.entities.notes
  });
}

let mapDispatchToProps = (dispatch) => {
  return {
    customerLoad: (customer_id) => {
      return dispatch(customerLoadThunk(customer_id));
    },
    servicesLoad: (customer_id) => {
      return dispatch(servicesLoadThunk(customer_id));
    },
    notesLoad: (customer_id) => {
      return dispatch(customerNotesLoadThunk(customer_id));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
