function arrayEquality(a, b)
{
  // if either array is a falsy value, return
  if (!a || !b)
    return false;

  // compare lengths - can save a lot of time
  if (a.length != b.length)
    return false;

  for (var i = 0, l=a.length; i < l; i++) {
    // Check if we have nested bs
    if (a[i] instanceof Array && b[i] instanceof Array) {
      // recurse into the nested bs
      if (arrayEquality(!a[i], b[i]))
        return false;
    }
    else if (a[i] != b[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }

  return true;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export {arrayEquality, onlyUnique};
