import {connect} from 'react-redux';
import MultiPictureComponent from '../components/MultiPicture/MultiPictureComponent';
import receiveMultiPictureThunk from '../thunks/receiveMultiPictureThunk';

let mapStateToProps = (state, ownprops) => {
  return(
  {
    appointment_id: state.appointment_id,
    service_order_id: state.service_order_id,
    pictures: state.pictures,
    picture_count: state.pictures.length,
    uploaded_picture_count: state.uploaded_picture_count,

  });
}

let mapDispatchToProps = (dispatch) => {
  return {
    processFile: (appointment_id, file) => {
      return dispatch(receiveMultiPictureThunk(appointment_id, file));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiPictureComponent);
