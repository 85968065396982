import React from 'react';

export default class Note extends React.Component {
  constructor(props) {
    super(props);
  }

  render()
  {
    let note = this.props.note;

    return(
      <div className="note simple">
        {this.simpleFormat(note.body)}
        <span className="meta muted">{note.author} @ {note.created_at}</span>
      </div>
    )
  }

  simpleFormat(string)
  {
    return(
      string.split('\n').filter(x=>x).map((item, key) => {
        return <p key={key}>{item}</p>
      }))
  }
}
