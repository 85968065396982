// based on jsfiddle.net/jperl/4p8Ru/

//Setup an rotate directive. It will rotate the div by the degrees attribute
app.directive('rotate', [function() {
  return {
    link: function(scope, element, attrs) {
      // Watch the degrees attribute and update the UI when it changes
      scope.$watch(attrs.degrees, function(rotateDegrees) {
        // Transform the CSS to rotate based on the new rotateDegrees
        element.css({
          '-moz-transform': 'rotate(' + rotateDegrees + 'deg)',
          '-webkit-transform': 'rotate(' + rotateDegrees + 'deg)',
          '-o-transform': 'rotate(' + rotateDegrees + 'deg)',
          '-ms-transform': 'rotate(' + rotateDegrees + 'deg)'
        });
      });
    }
  };
}]);
