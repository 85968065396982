import React from 'react'
import { render } from 'react-dom'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { arrayEquality } from '../../tools/arrayOperations'

export default class SimpleExample extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: {},
      zoom: 16
    }

    this.mapHelper = new MapQuestHelper(this.props.mapID);
  }

  componentDidMount() {
    this.updateLocation();
  }

  componentDidUpdate(prevProps)
  {
    let originalDefaultValue = [prevProps.location.latitude, prevProps.location.longitude, prevProps.zoom];
    let currentDefaultValue = [this.props.location.latitude, this.props.location.longitude, this.props.zoom];

    let externalChange = !arrayEquality(originalDefaultValue, currentDefaultValue);

    if(externalChange)
    {
      this.updateLocation();
    }
  }

  updateLocation () {
    this.setState({location: this.props.location, zoom: this.props.zoom || this.state.zoom}, function () {
      if (this.renderMap()) {
        let self = this
        self.mapHelper.reset().then(function () {
          self.mapHelper.addMarker(self.state.location.latitude, self.state.location.longitude);
        });
      }
    });
  }

  renderMap () {
    return !!(this.state.location && this.state.location.latitude && this.state.location.longitude);
  }

  render() {
    if (this.renderMap()) {
      return (
        <div id={this.props.mapID}>&nbsp;</div>
      )
    } else {
      return null;
    }
  }
}
