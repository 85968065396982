import Sortable from 'sortablejs';

window.mapHelper;

function afterSortAppointments () {
  let form = $('.waypoints-form');

  $('.appointment-row', form).each(function(index, element) {
    let position = $('.appointment-position', element);
    $(position).val(index + 1);

    $('.star', element).addClass('display-none');
    $($('.star', element)[index]).removeClass('display-none');
  })

  var data = {}
  var position = 1

  $('.sortable-waypoint').each(function () {
    var appointmentId = $('input[name="appointment_id"]', $(this)).val();

    data[appointmentId] = {
      route_id: $('#route_id').val(),
      position: position
    }

    position += 1;
  })

  sendShuffleData(data);
}

function sendShuffleData (data) {
  $('#api-access').show();

  $.ajax({
    url:'/appointments/shuffle.json',
    type: 'PUT',
    data: {
      shuffle: data,
      skip_status_change: true
    },
    beforeSend: function () {
      $('.btn-confirm-shuffle').attr('disabled', 'disabled')
    },
    success: function(resource) {
      $('#api-access').hide();
      $('.btn-confirm-shuffle').removeAttr('disabled')

      window.mapHelper.reset().then(function() {
        window.mapHelper.outlineRoutes(resource.routes, true, {});
      })
    },
    error: function(resource) {
      $('#api-access').hide();
      $('#api-error').show();
      $('.btn-confirm-shuffle').removeAttr('disabled')
    }
  });
}

$(document).ready(function() {
  $('[data-init="map"]').each(function () {
    var routeId = $('#route_id').val();
    window.mapHelper = new MapQuestHelper('map');

    $.ajax({
      url:'/routes/' + routeId + '.json',
      type: 'GET',
      beforeSend: function () {
        $('.btn-confirm-shuffle').attr('disabled', 'disabled')
      },
      success: function(resource) {
        $('#api-access').hide();
        $('.btn-confirm-shuffle').removeAttr('disabled')

        window.mapHelper.reset().then(function() {
          window.mapHelper.outlineRoutes([resource.route], true, {});
        })
      },
      error: function(resource) {
        $('#api-access').hide();
        $('#api-error').show();
        $('.btn-confirm-shuffle').removeAttr('disabled')
      }
    });
  })

  if ($('body.routes.timeframes').length > 0 || $('body.routes.update_timeframes').length > 0 ||
      $('body.routes.positions').length > 0 || $('body.routes.update_positions').length > 0) {
    $('input.timepicker').timepicker({
      minuteStep: 15,
      showMeridian: true
    });

    var sortable = document.getElementById('sortable');

    new Sortable(sortable, {
      animation: 150,
      ghostClass: "sortable-ghost",  // Class name for the drop placeholder
      chosenClass: "sortable-chosen",  // Class name for the chosen item
      dragClass: "sortable-drag",  // Class name for the dragging item
      handle: ".sortable-waypoint",
      onUpdate: afterSortAppointments
    });
  }
})
