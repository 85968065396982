function pictures(state=[], action=null)
{
  switch( action.type )
  {
    case 'RECEIVE_PICTURE':
      let pix = state || [];
      return( [...pix, action.file] )

    default:
      return state
  }
}

function uploaded_picture_count(state = 0, action=null)
{
  switch( action.type )
  {
    case 'UPLOAD_SUCCEEDED':
      return(state+1)

    default:
      return(state)
  }
}

export default function MultiPictureReducer(state={}, action=null)
{
  return(
    {
      pictures: pictures(state.pictures, action),
      uploaded_picture_count: uploaded_picture_count(state.uploaded_picture_count, action),
      appointment_id: state.appointment_id,
      service_order_id: state.service_order_id
    }
  )
}
