app.controller('LocationsCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Location',
  function($scope, $routeParams, $location, fixRailsSerializer, Location) {
    $scope.displayAmbiguous = false;
    $scope.mapHelper = new MapQuestHelper('map');
    $scope.mapHelper.reset();

    Location.get( $routeParams['location_id'] ).then(function(resource) {
      $.post('/observations.json', {
        observations: {
          identifier: 'Locations map',
          value: document.current_user_id || 0
        }
      });

      $scope.location = resource;

      if ($scope.location.latitude && $scope.location.longitude) {
        $scope.mapHelper.addMarker($scope.location.latitude, $scope.location.longitude);
      } else {
        $scope.displayAmbiguous = true;
      }
    });
  }
]);
