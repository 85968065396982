$(function()
{
    $('.datepicker').datepicker({ autoclose: true, format: 'yyyy-mm-dd' });

    $('.monday-picker').datepicker({
      format: 'yyyy-mm-dd',
      weekStart: 1,
      daysOfWeekDisabled: "0,2,3,4,5,6",
      autoclose: true,
      todayHighlight: true
    });

    $(document).on('click', '.previewable', function(event) {
      let element = $(event.target);
      element.location = element.data('fullSizeUrl');
      show_large(element)
    });

    function show_large(element) {
      $('<div class="modal modal-wide"><div class="modal-body"><img src="' + element.location + '" width="800" /></div></div>').modal();
    }

    function load_state_data( oSettings, oData )
    {
      $("thead select").each(function( index, node )
      {
        column_index = $('td', node.parentElement.parentElement).index(node.parentElement) ;

        $.each(oData.aoSearchCols, function(index, value)
        {
          if( column_index == index )
          {
            current_option = $("option[value=" + value.sSearch + "]", node);
            current_option.attr('selected', 'selected');
          }
        });
      });
    }

    $('.viewmode-control').click(
            function(){
            $.ajax({url: '/viewmode', type: 'PUT', success: function(){ window.location = window.location;}});
            }
    );

    var dataTable = $('.datatable').dataTable({
      bStateSave:  true,
      fnStateLoadParams: load_state_data,
      bProcessing: true,
      bFilter: $('.datatable').data('search') || false,
      bServerSide: true,
      sAjaxSource: $('.datatable').data('source'),
      aLengthMenu: [10, 25, 100, 1000],
      iDisplayLength: 25
    });

    $('.table-sortable').dataTable({
        bStateSave:  true
    });

    $("thead select").change( function () {
      var node = this;

      /* Filter on the column (the index) of this element */
      column_index = $('td', node.parentElement.parentElement).index(node.parentElement) ;
      dataTable.fnFilter( this.value, column_index );
    } );

    $("tfoot input").keyup( function () {
        /* Filter on the column (the index) of this element */
        dataTable.fnFilter( this.value, $("tfoot input").index(this) );
    } );

    $("tfoot input.datepicker").change( function () {
        /* Filter on the column (the index) of this element */
        dataTable.fnFilter( this.value, $("tfoot input").index(this) );
    } );
    function focus_on_first_input()
    {
      $("input:text, input[type='email'], textarea, select").not('.do_not_highlight').first().focus();
    }

    focus_on_first_input();

    function prevent_multiple_submissions(form)
    {
      form.one('submit', function() {
        $(this).find('input[type="submit"]').attr('disabled','disabled');
      });
    }

    prevent_multiple_submissions($('form.prevent-multiple-submissions'));

    $('.handle a').bind('click', function(event){
      event.stopPropagation();
    });

    $('.handle').css('cursor', 'pointer');
    $('.handle').bind('click', function() {
      slider_rows = $(this).nextUntil('.handle');
      slider_rows.toggle();
    });

    $('.btn-one-time').click(function() {
      var form = $(this).parents('form:first');

      if (form.attr('novalidate') == 'novalidate' || form.valid) {
        $(this).prop('disabled', true);
        form.submit();
      }
    });

    $("#environment-banner-button").click(function(e) {
      $("#environment-banner").hide();
    });

    var segments;
    $.ajax({url: '/market_segments.json', type: 'GET', success: function(res){ segments = res.stores; init_market_segments()}});

    // market segment info
    function market_segment_selector(client_id, id_prefix) {
      var res = segments.filter( function (obj) {
        return obj.client_id == client_id
      })

      if (res.length > 0) {
        display_market_segment_options(res, id_prefix);
      } else {
        hide_market_segment_options();
      }
    }

    function display_market_segment_options(opts_to_display, id_prefix) {
      hide_market_segment_options();
      var segment = document.getElementById("hidden-selector")

      select = document.createElement('select');
      select.id = id_prefix + 'store_id';
      select.name = 'store_id';

      if(id_prefix != null && id_prefix.length > 0) {
        select.name = id_prefix + '[store_id]';
      }
      var opts = "<option value></option>"

      for ( var i = 0; i < opts_to_display.length; i++ ) {
        var selected_text = ''
        if( opts_to_display[i].selected ) {
          selected_text = " selected='selected' ";
        }
        opts = opts + "<option value='" + opts_to_display[i].id + "'" + selected_text + ">" + opts_to_display[i].name + "</option>"
      }

      select.innerHTML = opts;
      segment.style.display="inline-block";
      segment.append(select);
    }

    function hide_market_segment_options() {
      var segment = document.getElementById("hidden-selector");
      var no_of_children = segment.children.length

      for ( var i = 0; i < no_of_children; i++) {
        segment.removeChild(segment.lastChild);
      }
      segment.style.display="none";
    }

    // display store id if set on page load, eg report show
    function init_market_segments() {
      var hidden = document.getElementById("hidden-selector");
      if (hidden != null) {
        var client_id = hidden.getAttribute('data-client-id');
        var id_prefix = hidden.getAttribute('data-id-prefix');
        var store_info = hidden.getAttribute('data-segment-info');
        if (client_id != '' && client_id != null) {
          if (store_info != '' && store_info != null) {
            var selected_store = segments.find(obj => obj.name == store_info)
            selected_store.selected = true;
          }

          market_segment_selector(client_id, id_prefix);
        }
      }
    }
});
