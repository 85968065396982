import React from 'react';
import TextInput from '../common/TextInput';
import CustomerBrief from './Brief';
import {Link} from 'react-router-dom'
import {trim} from '../../tools/string';

export default class CustomerSearch extends React.Component {
  constructor(props) {
    super(props);
    // Zipcode Stati: [null, 'Unzoned', 'Invalid', 'In Service', 'Not Serviced', 'Error']
    this.state = {zipcode_status: null};

    this.search_criteria = {};
    this.updateSearch = this.updateSearch.bind(this);
    this.checkZip = this.checkZip.bind(this);
    this.acceptZipInfo = this.acceptZipInfo.bind(this);
    this.renderAddCustomer = this.renderAddCustomer.bind(this);
    this.renderZipcodeMessage = this.renderZipcodeMessage.bind(this);
  }

  acceptZipInfo(response)
  {
    if(response.length == 0)
    {
      this.setState({'zipcode_status': 'Invalid'})
    }
    else if( response.length > 1 )
    {
      // This shouldn't happen
      this.setState({'zipcode_status': 'Invalid'})
    }
    else
    {
      this.setState({'zipcode_status': response[0].status})
    }

    this._zipsearch = null;
  }

  failZipSearch(response)
  {
    this.setState({'zipcode_status': 'Error'})
  }

  checkZip(value)
  {
    this.setState({'zipcode_status': null})

    if(this._zipsearch)
    {
      this._zipsearch.abort();
      this._zipsearch = null;
    }

    if(value && value.length == 5)
    {
      this._zipsearch = $.get('/zipcodes/search.json', {code: value}).then(this.acceptZipInfo, this.zipSearchFailure)
    }
  }

  updateSearch(name, value)
  {
    if( name == "zipcode" ) { this.checkZip(value) }

    this.search_criteria[name] = trim(value);
    this.props.customerSearch(this.search_criteria);
  }

  render()
  {
    return(
      <div>
        <div className="page-header ng-scope">
          <h2>Search for Existing Customers</h2>
        </div>

        <div className="row">
          <div className="span6">
            <form className="simple_form form form-horizontal" id="edit_service_order_wizard_1" method="post">
              <div className="hidden">
                <input name="utf8" type="hidden" value="✓" />
                <input name="authenticity_token" type="hidden" value={this.props.authenticity_token} />
              </div>
              <TextInput label="Zipcode" name="zipcode" required={true} autocomplete="off" onChange={this.updateSearch} />
              <TextInput label="Last Name" name="last_name" required={true} autocomplete="off" onChange={this.updateSearch} />
              <TextInput label="First Name" name="first_name" required={true} autocomplete="off" onChange={this.updateSearch} />

              {this.renderInstructions()}


            </form>
          </div>
          <div className="span6">
            <div className="matching_customers">
              {this.renderZipcodeMessage()}
              {this.renderCustomers(this.props.customers)}
              {this.renderAddCustomer(this.props.allowAddCustomer)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAddCustomer(allowNewCustomerFlag=false)
  {
    let zipstate = allowNewCustomerFlag && this.state.zipcode_status;

    if( ['In Service'].includes(zipstate) )
    {
      return(
        <div>
          <hr />
          <Link to={{pathname: '/customers/new', state: {...this.search_criteria}}} className="btn">New Customer</Link>
        </div>
      )
    }
  }

  renderZipcodeMessage(allowNewCustomerFlag)
  {
    let zipstate = this.state.zipcode_status;

    if(!zipstate)
    {
      return(null);
    }
    else if( zipstate == "In Service" )
    {
      return(
        <div className="zipcode-message">
          <div className="alert alert-success fancy-alert">
            <div className="alert-awesome">
              <i className="fa fa-check"></i>
            </div>
            <div className="alert-content">
              <h4>{this.search_criteria.zipcode} is in our normal service area.</h4>
            </div>
          </div>
        </div>
      )
    }
    else if(zipstate == "Invalid")
    {
      return(
        <div className="zipcode-message">
          <div className="alert alert-error fancy-alert alert-tall">
            <div className="alert-awesome">
              <i className="fa fa-flag"></i>
            </div>
            <div className="alert-content">
              <h4>We do not recognize that zipcode.</h4>
            </div>
          </div>
        </div>
      )
    }
    else if(zipstate == "Error" || zipstate == "Unzoned")
    {
      return(
        <div className="zipcode-message">
          <div className="alert alert-error fancy-alert alert-tall">
            <div className="alert-awesome">
              <i className="fa fa-flag"></i>
            </div>
            <div className="alert-content">
              <h4>This area is under review by SAW and may or may not be serviced. Continue entering information. We will contact you if we determine that it is out of our service area.</h4>
            </div>
          </div>
        </div>
      )
    }
    else if( zipstate == "Not Serviced" || zipstate == "Cancelled" )
    {
      return(
        <div className="zipcode-message">
          <div className="alert alert-error fancy-alert alert-tall">
            <div className="alert-awesome">
              <i className="fa fa-flag"></i>
            </div>
            <div className="alert-content">
              <h4>Service may not be available</h4>
              <p>{this.search_criteria.zipcode} is outside of our normal area. Services may be subject to a travel fee. Call the office or <a href="mailto:customerservice@sawenterprises.com">email customer service</a> for details.</p>
            </div>
          </div>
        </div>
      )
    }
    else
    {
      return(null);
    }
  }

  renderTitle(results)
  {
    let zipstate = this.state.zipcode_status;

    if (zipstate == 'In Service') {
      var title = results + " Matching Customers:";
      if (results == 0)
      {
        title = "No Matching Customers";
      } else if (results == 1) {
        title = "Matching Customer:";
      }
      return(
        <h2>{title}</h2>
      )
    }
  }

  renderCustomers(customers)
  {

    let zipstate = this.state.zipcode_status;

    if( !['In Service'].includes(zipstate) )
      return;

    if( !customers )
    {
      return(
          <div className="well">
            You must enter:
            <li>A five-digit zipcode</li>
            <li>At least one letter in the last name box</li>
            <li>At least one letter in the first name box</li>
          </div>
          )
    }
    else
    {
      return(
        <div>
          {this.renderTitle(customers.length)}

          {customers.map((customer) => {return(
            <div key={customer.id}>
              <div style={{display: 'inline-block'}}>
                <CustomerBrief customer={customer} />
              </div>
              <div style={{display: 'inline-block'}}>
                <Link to={'/customers/' + customer.id} className="btn btn-after-fact">This Customer</Link>
              </div>
            </div>
          )})}
        </div>
      )
    }
  }

  renderInstructions()
  {
    return(
              <div className="well">
                <h2>Help</h2>
                <p>The first step in entering a request for service is to determine whether this is a new or existing customer. Enter the customer's information above. Results should appear after you have entered a 5-digit zipcode and at least three characters of the person's last name.</p>
                <p>The system will show a limited number of matches. You may need to enter a first name to find the exact match.</p>
                <h4>Which Customer to Pick</h4>
                <p>
                  Previous versions of this program allowed users to create more than one entry for a customer. If you see a customer on the list more than once, <b>pick the top entry.</b> The list is in <b>reverse</b> chronological order, meaning the most recently used entry should be on the top.
                </p>
                <h4>Example</h4>
                <p>
                  try seaching <b>60629</b> with last name <b>Johnson</b>.
                </p>
              </div>
    );
  }
}
