import {connect} from 'react-redux';
import EditForm from '../../components/Customers/Edit';
import {customerLoadThunk, customerSaveThunk} from '../../thunks/customerThunk';

let mapStateToProps = (state, ownprops) => {
  return(Object.assign({}, ownprops, {customer: state.entities.customer, customer_id: ownprops.match.params.customer_id}))
}

let mapDispatchToProps = (dispatch) => {
  return {
    customerSave: (data) => {
      return dispatch(customerSaveThunk(data));
    },
    customerLoad: (customer_id) => {
      return dispatch(customerLoadThunk(customer_id));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
