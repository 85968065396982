import React from 'react';
import TextInput from '../common/TextInput';
import CheckboxInput from '../common/CheckboxInput';

export default class PhoneNumberRow extends React.Component {
  constructor(props)
  {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.rowClassName = this.rowClassName.bind(this);
  }

  handleChange(name, value)
  {
    let change = {};
    change[name]=value;
    this.props.onChange(change);
  }

  rowClassName()
  {
    if(!this.props) {
      return(null) }
    else if(this.props['_destroy']) {
      return('doomed') }
    else {
      return(null) }
  }

  render()
  {
    return(
      <tr className={this.rowClassName()}>
        <td>
          <TextInput autocomplete="off" bootstrap={false} name="description" value={this.props.description} onChange={this.handleChange} data-lpignore="true" />
        </td>
        <td>
          <TextInput autocomplete="off" bootstrap={false} name="number" value={this.props.number} onChange={this.handleChange} data-lpignore="true" />
        </td>
        <td>
          <CheckboxInput name="allow_sms" label="Text Me!" bootstrap={false} onChange={this.handleChange} value={this.props.allow_sms} />
        </td>
        <td>
          <CheckboxInput name="_destroy" label="Delete" bootstrap={false} onChange={this.handleChange} value={this.props._destroy} />
        </td>
      </tr>
    )
  }
}
