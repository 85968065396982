function hideTechniciansPartActions(elem) {
  var id = $(elem).attr('data-technicians-part-id');
  var table = $(elem).closest('table');

  $('[data-technicians-part-actions-row=' + id + ']', table).addClass('display-none');
  $(elem).html('Show Actions');
  $(elem).attr('data-technicians-part-actions-opened', false);
}

function showTechniciansPartActions(elem) {
  var id = $(elem).attr('data-technicians-part-id');
  var table = $(elem).closest('table');

  $('[data-technicians-part-actions-row=' + id + ']', table).removeClass('display-none');
  $(elem).html('Hide Actions');
  $(elem).attr('data-technicians-part-actions-opened', true);
}

$(document).ready(function () {
  $(document).on('click', '[data-technicians-part-actions]', function (event) {
    event.preventDefault();
    var elem = event.target;

    $('[data-technicians-part-actions-opened=true]').each(function(index, otherElem) {
      var id = $(elem).attr('data-technicians-part-id');
      var otherId = $(otherElem).attr('data-technicians-part-id');

      if (id != otherId) {
        hideTechniciansPartActions(otherElem);
      }
    });

    if($(elem).attr('data-technicians-part-actions-opened') == 'true') {
      hideTechniciansPartActions(elem);
    } else {
      showTechniciansPartActions(elem);
    }
  });

  $(document).on('ajax:beforeSend', '[data-technicians-part-actions-row]', function (event) {
    $('.technicians-part-alert').addClass('display-none');
    $('.technicians-part-alert .message-body').html(null);
    $('.fixed-loader').removeClass('display-none');
  });

  $(document).on('ajax:error', '[data-technicians-part-actions-row]', function (event, jqXHR) {
    errors = JSON.parse(jqXHR.responseText).errors;

    $.each(errors, function (idx, error) {
      var message = document.createElement('div');
      $(message).html(error);
      $('.technicians-part-alert .message-body').append(message);
    })

    $('.technicians-part-alert').removeClass('display-none');
    $('.fixed-loader').addClass('display-none');
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".technicians-part-alert").offset().top - 100
    }, 1000);
  });

  $(document).on('ajax:success', '[data-technicians-part-actions-row]', function (event) {
    let elem = event.target;
    let container = $(elem).closest('[data-technicians-part-reload-url]');
    let url = container.data('technicians-part-reload-url');

    $.ajax({
      url: url,
      data: {
        format: 'js'
      }
    }).done(function () {
      $('.fixed-loader').addClass('display-none');
    })
  });
});
