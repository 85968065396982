$(function(){
  function serializeIds(){
    var out = [];

    $(".sort-handle").each(function(i, handle){
      out.push({'id': $(handle).data("id"), 'position': i+1});
    });

    return out; 
  };
  
  $("#checklist_template_items tbody").sortable({
    handle: '.sort-handle',
    update: function(){
      params = {'checklist_template_items': serializeIds()};

      $.ajax({
        url: "/checklist_template_items?authenticity_token=" + AUTH_TOKEN,
        items: 'tr',
        dataType: "json",
        type: 'put',
        data: JSON.stringify(params),
        contentType: "application/json",
        complete: function(){
          $("#checklist_template_items tr td").effect("highlight", 1000);
        }
      });
    }
  });
});
